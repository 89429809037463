import AnalyticsService, { EventType } from "../_service/analytics-service";

import { Avatar } from "./avatar.component";
import IconButton2 from "./icon-button-2-component";
import { PostService } from "../_service";
import UIcon from "./uicon-component";
import { createPostDetailPageRouteFromSlug } from "../_pages/post-detail.page";
import { createUserWallPageRoute } from "../_pages/user-wall.page";
import cx from "classnames";
import { getSectionPageRouteFromSlug } from "../_pages";
import { history } from "../_config";
import moment from "moment";
import removeMd from "remove-markdown";
import { useAppService } from "../hooks/use-app-service";
import { useState } from "react";

/**
 * @param {boolean} displayLikeCommentIcons - if true, display like and comment icons
 */

export function MinimizedPostCard({
  post,
  user,
  addToast = (e: any) => {},
  updatePost = (post: any) => {},

  displayLikeCommentIcons = false,
}: any) {
  const { title, description } = post;
  const [inProgress, setInProgress] = useState(false);
  const { analyticsService } = useAppService();

  // post preferences
  const { hideLikes, hideComment, hideCreator, hideDate } =
    post.preferences ?? {};

  function getHeadline() {
    if (title && title.trim().length > 0) {
      return title;
    }
    if (description) {
      let text = removeMd(description);
      if (text.length > 60) {
        text = text.substring(0, 60) + " ...";
      }
      return removeMd(text);
    }
    return undefined;
  }

  const handleLikeClick = () => {
    if (inProgress || !user) {
      // if already loading or user not logged in, do nothing
      return;
    }

    setInProgress(true); // setLoading

    const promise = post.isLikedByMe
      ? PostService.dislikePost(user, post.id)
      : PostService.likePost(user, post.id);

    promise
      .then(({ post }) => {
        updatePost(post);
        if (!post.isLikedByMe) {
          AnalyticsService.logEvent(
            EventType.Post.name,
            Number(EventType.Post.like)
          );
          // mixpanel event
          const eventData = {
            userType: post.community.myRole,
            groupName: post?.group.name,
            sectionName: post?.tab.name,
            postUrl:
              window.location.origin + createPostDetailPageRouteFromSlug(post),
          };

          analyticsService.track("like-post", eventData);
        }
      })
      .catch((err) => {
        console.error({ err });
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <div
      className={cx(
        "flex theme-bg-surface cursor-pointer border-b theme-border-default items-start",
        {
          "p-4": !displayLikeCommentIcons,
          "px-4 pt-4": displayLikeCommentIcons,
        }
      )}
      key={post.id}>
      {!hideCreator && (
        <Avatar
          onClick={(e) => {
            history.push(createUserWallPageRoute(post.createdBy.id));
          }}
          user={{ ...post.createdBy, name: "" }}
          extraInfo={undefined}
        />
      )}
      <div className="flex-grow">
        <div
          onClick={(e) => {
            history.push(createPostDetailPageRouteFromSlug(post));
          }}
          className="text-lg font-semibold line-clamp-1">
          {getHeadline() ? (
            getHeadline()
          ) : (
            <div className="theme-text-subtitle-2 ">No visible description</div>
          )}
        </div>
        {/* post details */}
        <div className="flex flex-col mt-1">
          {/* post type */}
          <PostType post={post} />
          {/* other post details */}

          <div>
            {post.createdBy.name} posted in
            <span
              onClick={() =>
                history.push(getSectionPageRouteFromSlug(post.group, post.tab))
              }
              className="font-semibold theme-text-primary">
              {" "}
              {post.group.name} {post.tab ? " - " + post.tab.name : ""}{" "}
            </span>
          </div>
          <div
            className={cx("text-xs theme-text-subtitle-2", {
              hidden: hideDate,
            })}>
            {moment
              .duration(moment(new Date()).diff(moment(post.createdAt)))
              .asDays() < 1
              ? moment(post.createdAt).fromNow()
              : "" + moment(post.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
        {/* likes and comments */}
        {displayLikeCommentIcons && (
          <div className="flex items-center">
            <div
              className={cx("flex items-center mr-4", { hidden: hideLikes })}>
              <button
                className={`flex items-center space-x-1 h-10 ${
                  post.isLikedByMe ? "theme-text-primary" : ""
                }${hideLikes ? "hidden" : "mr-2"}`}
                onClick={handleLikeClick}>
                <UIcon
                  className={
                    post.isLikedByMe
                      ? "theme-text-primary"
                      : "theme-text-subtitle-1"
                  }
                  icon="thumbs-up"
                  size="lg"
                  solid={post.isLikedByMe}
                />
                <span className="text-xs">
                  &nbsp;
                  {post.likeCount > 0
                    ? post.likeCount === 1
                      ? "1"
                      : post.likeCount
                    : ""}
                </span>
              </button>
            </div>
            <div className={cx("flex items-center", { hidden: hideComment })}>
              <IconButton2
                onClick={(e) => {
                  history.push(createPostDetailPageRouteFromSlug(post));
                }}
                icon="comment-alt"
                label={`${post.commentCount}`}
                iconClass="text-lg"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Display poll tag if post contains poll
 */
function PostType({ post }: any) {
  if (post.poll) {
    return (
      <div
        className="text-xs max-w-max rounded font-semibold mr-2 flex items-center flex-shrink-0 theme-text-background"
        style={{
          backgroundColor: "#FFE27B",
          padding: "3px 8px 3px 3px",
        }}>
        <img
          src="assets/vectors/poll-small-icon.svg"
          alt=""
          style={{ width: "16px" }}
          className="mr-1"
        />
        {post.poll.isQuiz ? "Quiz" : "Poll"}
      </div>
    );
  }

  // nothing
  return <></>;
}
