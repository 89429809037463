import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useAppService } from "../../hooks/use-app-service";
import useLang from "../../hooks/use-lang.hook";
import imageIcon from "../../_assets/vectors/image-black-icon.svg";
import imageGrayIcon from "../../_assets/vectors/image-icon.svg";
import { history } from "../../_config";
import razorpayCurrencyCodes from "../../_config/razorpay-currency-codes.config.json";
import {
  createGroupPageUrlFromSlug,
  getGroupPageRouteFromSlug,
} from "../../_pages";
import { SUBSCRIPTION_PAGE_ROUTE } from "../../_pages/user-management-settings/subscriptions/subscriptions-list/subscriptions-page";
import { GroupService } from "../../_service";
import { setGroups } from "../../_store/_actions/group.actions";
import Validator from "../../_utils/validator";
import I18 from "../atoms/i18";
import { TabBody } from "../atoms/tab-body.component";
import { TabPills } from "../atoms/tab-pills.component";
import Tooltip from "../atoms/tooltip";
import { Button } from "../button.component";
import { ComponentDisplay } from "../component-switcher/component-switcher.component";
import {
  ConvertToSlugString,
  CreateSlugComponent,
  limitMaxCharacterTo255,
} from "../create-slug-modal";
import { Checkbox } from "../form-controls/checkbox.component";
import { FileInput } from "../form-controls/file-input";
import { Radio } from "../form-controls/radio.component";
import IconButton2 from "../icon-button-2-component";
import ImageCropModal from "../image-crop/image-crop-modal";
import { Modal } from "../modal.component";
import { TextInput } from "../text-input.component";
import UIcon from "../uicon-component";
import UnsplashImagePicker from "../unsplash-image-picker";

const colorCodes = ["#E0B386", "#29D3DA", "#FB9696", "#3589D8", "#FBBB00"];

const TabButton = Object.freeze({
  details: "Basic Detail",
  slug: "Settings",
});

function CreateGroupModalComponent({
  user,
  community,
  communityPac,
  group = null,
  groups,
  updateGroup = (e) => {},
  updateGroups = (e) => {},
  active = false,
  setActive = (e) => {},
}) {
  // form states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groupBanner, setGroupBanner] = useState("");
  const [image, setImage] = useState(null);
  const [file, selectFile] = useState(null);
  const [color, setColor] = useState("");
  const [price, setPrice] = useState(
    group && group.price ? group.price / 100 : 0
  );
  const [currency, setCurrency] = useState("INR");
  const [groupType, setGroupType] = useState("open");
  const [gatedTokenAddress, setGatedTokenAddress] = useState("");
  const [isOnlyVisibleToMembers, setIsOnlyVisibleToMembers] = useState(false);
  const [isFormSubmittable, setIsFormSubmittable] = useState(
    group ? true : false
  ); // to disable submit button

  const [selectedTab, setSelectedTab] = useState(TabButton.details);

  // Slugs related
  const [slug, setSlug] = useState();
  const [slugTitle, setSlugTitle] = useState(null);
  const [slugDescription, setSlugDescription] = useState(null);
  const [OGTitle, setOGTitle] = useState(null);
  const [OGDescription, setOGDescription] = useState(null);
  const [OGImage, setOGImage] = useState(null);

  useEffect(() => {
    if (Validator.hasValue(group) && active) {
      if (group.name) {
        setName(group.name);
      }
      if (group.description) {
        setDescription(group.description);
      }
      if (group.banner) {
        setGroupBanner(group.banner);
      }
      if (group.color) {
        setColor(group.color);
      }
      if (group.currency) {
        setCurrency(group.currency);
      }
      if (group.price) {
        setPrice(group.price / 100);
      }
      if (group.groupType) {
        setGroupType(group.groupType);
      }
      if (group.gatedTokenAddress) {
        setGatedTokenAddress(group.gatedTokenAddress);
      }
      if (group.isOnlyVisibleToMembers) {
        setIsOnlyVisibleToMembers(group.isOnlyVisibleToMembers);
      }
      if (group?.meta?.slug) {
        setSlug(group.meta.slug);
      }
      if (group?.meta?.title) {
        setSlugTitle(group.meta.title);
      }
      if (group?.meta?.description) {
        setSlugDescription(group.meta.description);
      }
      if (group?.meta?.openGraphTitle) {
        setOGTitle(group.meta.openGraphTitle);
      }
      if (group?.meta?.openGraphDescription) {
        setOGDescription(group.meta.openGraphDescription);
      }
      if (group?.meta?.OGImage) {
        setOGImage(group.meta.OGImage);
      }
    }
  }, [group, active]);

  // const [errors, setErrors] = useState({});
  const resetFormAndClose = () => {
    setImage(null);
    setName("");
    setPrice(0);
    setDescription("");
    setGroupType("");
    setIsFormSubmittable(false);
    setActive(false);
    setSelectedTab(TabButton.details);
    clearSlugMeta();
    selectFile(undefined);
    setColor("");
  };

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }
  if (!group && !active) {
    return null;
  } else if (!active) {
    return null;
  }

  return (
    <>
      <Modal
        className="CreateGroupModal"
        active={active}
        width="520px"
        padding={false}
        setActive={(e) => {
          setActive(e);
          resetFormAndClose();
        }}>
        <div className="theme-bg-surface">
          {/* headers */}
          <div className="pl-4 pr-2 py-2 theme-bg-default flex items-center justify-between">
            <span className="font-bold theme-text-heading-1">
              {group ? <I18>Update group</I18> : <I18>Create group</I18>}
              {group && (
                <span className="theme-text-heading-2 font-semibold ml-3">
                  Id: {group.id}
                </span>
              )}
            </span>
            <IconButton2
              hoverable
              icon="cross"
              size="md"
              iconClass="theme-text-subtitle-2"
              onClick={() => {
                resetFormAndClose();
              }}
            />
          </div>
          <div className="flex justify-start theme-bg-default tab-holder px-5">
            <TabPills
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={[
                { label: TabButton.details, key: TabButton.details },
                {
                  label: TabButton.slug,
                  key: TabButton.slug,
                },
              ]}
            />
          </div>
          {/* body */}
          <TabBody
            selectedTab={selectedTab}
            components={{
              [TabButton.details]: (
                <CreateGroupBody
                  community={community}
                  user={user}
                  group={group}
                  groups={groups}
                  updateGroups={updateGroups}
                  setActive={setActive}
                  communityPac={communityPac}
                  updateGroup={updateGroup}
                  // Form Props
                  name={name}
                  setName={setName}
                  description={description}
                  setDescription={setDescription}
                  setSelectedTab={setSelectedTab}
                  image={image}
                  groupBanner={groupBanner}
                  setGroupBanner={setGroupBanner}
                  setImage={setImage}
                  color={color}
                  setColor={setColor}
                  price={price}
                  setPrice={setPrice}
                  groupType={groupType}
                  setGroupType={setGroupType}
                  currency={currency}
                  setCurrency={setCurrency}
                  isOnlyVisibleToMembers={isOnlyVisibleToMembers}
                  setIsOnlyVisibleToMembers={setIsOnlyVisibleToMembers}
                  file={file}
                  selectFile={selectFile}
                  gatedTokenAddress={gatedTokenAddress}
                  setGatedTokenAddress={setGatedTokenAddress}
                  isFormSubmittable={isFormSubmittable}
                  setIsFormSubmittable={setIsFormSubmittable}
                  // Slugs
                  slug={slug}
                  slugDescription={slugDescription}
                  slugTitle={slugTitle}
                  OGTitle={OGTitle}
                  OGDescription={OGDescription}
                  OGImage={OGImage}
                  clearSlugMeta={clearSlugMeta}
                />
              ),
              [TabButton.slug]: (
                <div className="p-4 mb-2">
                  <CreateSlugComponent
                    community={community}
                    setActive={setActive}
                    slug={ConvertToSlugString(slug === null ? name : slug)}
                    setSlug={setSlug}
                    slugPrefix="g"
                    title={limitMaxCharacterTo255(
                      slugTitle == null ? name : slugTitle
                    )}
                    setTitle={setSlugTitle}
                    description={limitMaxCharacterTo255(
                      slugDescription == null ? description : slugDescription
                    )}
                    setDescription={setSlugDescription}
                    OGTitle={limitMaxCharacterTo255(
                      OGTitle == null ? name : OGTitle
                    )}
                    setOGTitle={(title) => {
                      setOGTitle(title);
                    }}
                    OGDescription={limitMaxCharacterTo255(
                      OGDescription == null ? description : OGDescription
                    )}
                    setOGDescription={setOGDescription}
                    OGImage={OGImage}
                    setOGImage={setOGImage}
                    OgImageSubtitle={
                      "If no URL is used, it will automatically picked your group thumbnail."
                    }
                    clearSlugMeta={clearSlugMeta}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              ),
            }}
          />
        </div>
      </Modal>
    </>
  );
}

/**
 * Create Group Body
 * @param {Object} community - community object
 * @param {Object} user - user object
 * @param {Object} group - group object
 * @param {Array} groups - groups array
 * @param {Function} updateGroups - update groups function
 * @param {Function} setActive - set create group modal visibility state
 * @param {Object} communityPac - community pac object
 * @param {Function} updateGroup - update group function
 * @param {String} name - Group name
 * @param {Function} setName - set name function
 * @param {String} description - Group description
 * @param {Function} setDescription - set description function
 * @param {Function} setSelectedTab - set selected tab function
 * @param {String} slug - Group slug
 * @param {String} slugTitle - Group slug title
 * @param {String} slugDescription - Group slug description
 * @param {String} OGTitle - Group OG title
 * @param {String} OGDescription - Group OG description
 * @param {String} OGImage - Group OG image
 * @param {Function} clearSlugMeta - clear slug meta function
 */
function CreateGroupBody({
  user,
  community,
  communityPac,
  group,
  updateGroup,
  groups,
  updateGroups,
  setActive,
  setSelectedTab,
  // Form Props
  name,
  setName,
  description,
  setDescription,
  image,
  groupBanner,
  setGroupBanner,
  setImage,
  color,
  setColor,
  price,
  setPrice,
  groupType,
  setGroupType,
  currency,
  setCurrency,
  isOnlyVisibleToMembers,
  setIsOnlyVisibleToMembers,
  gatedTokenAddress,
  setGatedTokenAddress,
  file,
  selectFile,
  isFormSubmittable,
  setIsFormSubmittable,
  // Slugs Related
  slug,
  slugTitle,
  slugDescription,
  OGTitle,
  OGDescription,
  OGImage,
  clearSlugMeta,
}) {
  const { analyticsService } = useAppService();
  const [isLoading, setIsLoading] = useState(false);
  const [activeCrop, setActiveCrop] = useState(false);
  const [activeUnSplash, setActiveUnSplash] = useState(false);
  const defaultTabName = "General";
  const defaultTabEmoji = "#";
  const postLevel = "admin";
  const lang = useLang();

  const onGroupCreate = (group) => {
    // group
    updateGroups([...groups, group]);
  };

  const onGroupUpdate = (group) => {
    updateGroup(group);
  };

  const submitForm = (e) => {
    // call the create group api
    setIsLoading(true);
    const meta = {
      slug: ConvertToSlugString(slug === null ? name : slug),
      title: slugTitle === null ? name : slugTitle,
      description: slugDescription === null ? description : slugDescription,
      openGraphTitle: OGTitle === null ? name : OGTitle,
      openGraphDescription:
        OGDescription === null ? description : OGDescription,
      openGraphImage: OGImage,
    };
    if (group) {
      updateTheGroup(user, {
        name,
        color,
        description,
        groupType,
        isOnlyVisibleToMembers,
        price: price * 100,
        meta: meta,
        gatedTokenAddress,
      });
    } else {
      createGroup(user, {
        name,
        description,
        color,
        groupType,
        isOnlyVisibleToMembers,
        price: price * 100,
        currency,
        postLevel,
        defaultTabName,
        defaultTabEmoji,
        communityId: community ? community.id : null,
        meta: meta,
        gatedTokenAddress,
      });
    }
  };

  const createGroup = (user, group) => {
    GroupService.createGroup(user, group)
      .then(({ group }) => {
        // close the form, check if banner needs to be uploaded
        if (!image) {
          setIsLoading(false);
          onGroupCreate(group);
          resetFormAndClose();
          analyticsService.track("create-group", {
            userType: community.myRole,
            groupName: group.name,
            description: group.description,
            groupType: groupType,
            groupUrl: createGroupPageUrlFromSlug(group),
          });
        } else {
          // upload the banner
          GroupService.uploadBanner(user, group.id, image)
            .then((response) => {
              setIsLoading(false);
              onGroupCreate(response.group);
              resetFormAndClose();
              analyticsService.track("create-group", {
                userType: community.myRole,
                groupName: group.name,
                description: group.description,
                groupType: groupType,
                groupUrl: group.url,
              });
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
        setActive(false);
        history.push(getGroupPageRouteFromSlug(group));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const updateTheGroup = (user, groupData) => {
    GroupService.updateGroup(user, group.id, groupData)
      .then((response) => {
        // close the form, check if banner needs to be uploaded
        if (!image) {
          setIsLoading(false);
          resetFormAndClose();
          onGroupUpdate(response.group);
        } else {
          // upload the banner
          GroupService.uploadBanner(user, response.group.id, image)
            .then((response) => {
              setIsLoading(false);
              resetFormAndClose();
              onGroupUpdate(response.group);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const resetFormAndClose = () => {
    setImage(null);
    setName("");
    setPrice(0);
    setDescription("");
    setGroupType("");

    setIsFormSubmittable(false);
    setActive(false);
    setSelectedTab(TabButton.details);
    clearSlugMeta();
    setActiveUnSplash(false);
    selectFile(undefined);
    setActiveCrop(true);
    setColor("");
    setCurrency("");
    setIsOnlyVisibleToMembers(false);
    setGatedTokenAddress("");
    setGroupBanner("");
  };

  const textAreaElement = useRef();

  let rows = description.split("\n").length;
  rows = rows > 3 ? rows : 3;

  const onFileSelect = async (file) => {
    setActiveCrop(true);
    let image = await URL.createObjectURL(file);
    selectFile(image);
  };

  return (
    <>
      <div className=" CreateGroupBody flex flex-col space-y-4">
        <section className="flex flex-col items-center mt-4">
          <div className="flex items-center flex-col w-2/3 justify-center">
            {/* preview */}
            <div
              className="group-color-preview-holder rounded w-full  theme-border-primary"
              style={{
                borderColor: color,
              }}>
              <div
                className="group-color-preview theme-bg-primary  rounded"
                style={{
                  backgroundColor: color,
                  backgroundImage: image
                    ? `url(${URL.createObjectURL(image)})`
                    : !color && Validator.hasValue(groupBanner)
                    ? `url(${groupBanner})`
                    : "",
                }}></div>
            </div>
            {/* choices */}
            <div className="flex items-center justify-center mt-2 cursor-pointer">
              {/* banner selector */}
              <Tooltip label="Upload from device">
                <FileInput
                  accept="image/*"
                  onChange={(file) => {
                    onFileSelect(file);
                    setActiveUnSplash(false);
                  }}>
                  <div
                    className={
                      "color-selector banner" + (image ? " selected" : "")
                    }
                    onClick={(e) => {
                      // select the banner
                      setColor(null);
                      // fileRef.current.click();
                      setActiveUnSplash(false);
                      setGroupBanner("");
                    }}>
                    <img src={image ? imageIcon : imageGrayIcon} alt="" />
                  </div>
                </FileInput>
              </Tooltip>
              {/* Unsplash image picker */}
              <Tooltip label="Search image">
                <div
                  className=" flex items-center place-content-center border theme-border-subtitle-1 rounded-full w-6 h-6"
                  onClick={() => {
                    setActiveUnSplash(true);
                  }}>
                  <UIcon icon="search" size="sx" />
                </div>
              </Tooltip>
              {/* theme color selector */}
              <div
                className={
                  "color-selector theme-bg-primary hover:scale-125 transform duration-300 ease-in-out" +
                  (!color && !image ? " selected shadow-md" : "")
                }
                onClick={(e) => {
                  setColor("");
                  setImage(null);
                  setActiveUnSplash(false);
                  setGroupBanner("");
                }}></div>
              {/* map the available color codes */}
              {colorCodes.map((colorCode, index) => (
                <div
                  style={{
                    backgroundColor: colorCode,
                  }}
                  key={colorCode}
                  className={
                    "color-selector hover:scale-125 transform duration-300 ease-in-out" +
                    (colorCode === color ? " selected shadow-md" : "")
                  }
                  onClick={(e) => {
                    setColor(colorCode);
                    setImage(null);
                    setActiveUnSplash(false);
                    setGroupBanner("");
                  }}></div>
              ))}
            </div>
          </div>
          <div className="px-12 text-center text-xxs font-light theme-text-subtitle-1">
            <I18>Size 16:9 or 1600 by 900</I18>
          </div>
        </section>
        {/* body */}
        <div className="flex flex-col space-y-5 px-5  theme-text-heading-2">
          {/* group name */}
          <div>
            <div className="font-bold mb-1 after:content-['*'] after:text-red-500 after:ml-0.5">
              <I18>Group name</I18>
            </div>
            <TextInput
              placeholder="Enter group name"
              inputHolderClassName="theme-bg-default "
              defaultValue={name}
              validator={(value) => {
                if (value && value.length > 2) {
                  setIsFormSubmittable(true);
                  return null;
                } else {
                  setIsFormSubmittable(false);
                  return "Valid group name required!";
                }
              }}
              onChange={(value) => {
                setName(value);
              }}
              large
              noMargin
            />
            <div className="text-xs theme-text-subtitle-1 mt-1">
              <I18>
                Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc
              </I18>
            </div>
          </div>

          {/* group description */}
          <div>
            <div className="font-bold mb-1">
              <I18>Group Description</I18>
            </div>
            <textarea
              type="text"
              ref={textAreaElement}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                window.setTimeout(() => {
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }, 0);
              }}
              className="theme-bg-default border theme-border-default rounded-lg px-4 py-2 w-full flex-grow block focus:outline-none resize-none"
              rows={rows}
              placeholder={lang.trans("Enter description here")}
            />
          </div>

          {/* group type */}
          <>
            <div className="font-bold">
              <I18>Group Type</I18>
            </div>
            <div className="flex gap-5">
              <Radio
                selected={groupType === "open"}
                onClick={() => {
                  setPrice(0);
                  setGroupType("open");
                }}>
                {/* Anyone can join */}
                <span className="font-semibold theme-text-heading-2">
                  <I18>Open</I18>
                </span>
              </Radio>
              <Radio
                selected={groupType === "closed-free"}
                onClick={() => {
                  setPrice(0);
                  setGroupType("closed-free");
                }}>
                <span className="font-semibold theme-text-heading-2">
                  <I18>Closed</I18>
                </span>
                {/* Require permission to join */}
              </Radio>
            </div>
            <div className="text-xs theme-text-subtitle-1">
              {groupType === "closed-paid" ? (
                <I18>User will have to pay to join the group</I18>
              ) : groupType === "open" ? (
                <I18>Visible to all & anyone can join</I18>
              ) : (
                <span>
                  <I18>
                    Either user can request to join this group OR User will have
                    to make a payment to subscribe to this group via membership.
                  </I18>
                  <Link
                    to={SUBSCRIPTION_PAGE_ROUTE}
                    onClick={() => setActive(false)}>
                    &nbsp;<I18>Create membership</I18>
                  </Link>
                </span>
              )}
            </div>
            {/* group price */}
            <ComponentDisplay IsDisplay={groupType === "closed-paid"}>
              <div className="grid grid-cols-2 gap-3 p-3 border theme-border-default rounded theme-bg-default my-2">
                <div className="">
                  <div className="font-semibold mb-1">
                    <span className="after:content-['*'] after:text-red-500 after:ml-0.5 ">
                      <I18>Group Price</I18>
                    </span>
                    &nbsp; {currency}
                  </div>
                  <TextInput
                    placeholder="Price*"
                    className="theme-bg-surface"
                    defaultValue={price}
                    validator={(value) => {
                      if (value && /^(\d)+$/.test(value) && value > 0) {
                        setIsFormSubmittable(true);
                        return null;
                      } else {
                        setIsFormSubmittable(false);
                        return "Valid price required!";
                      }
                    }}
                    onChange={(value) => {
                      setPrice(value);
                    }}
                    large
                    noMargin
                  />
                </div>
                <div className="">
                  <CurrencySelector
                    currency={currency}
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                  />
                </div>
              </div>
            </ComponentDisplay>
          </>       

          {/* group visibility */}
          <div
            className="flex cursor-pointer"
            onClick={(e) => {
              setIsOnlyVisibleToMembers(!isOnlyVisibleToMembers);
            }}>
            <Checkbox selected={isOnlyVisibleToMembers} />
            <div>
              <h3 className="font-semibold">
                <I18>Make this a private group</I18>
              </h3>
              <div className="theme-text-subtitle-1 text-xs">
                <I18>
                  Private group will only be visible to members who are part of
                  it. Admin can add members manually from member list
                </I18>
              </div>
            </div>
          </div>
          {/* show section, only if creating group */}
          {group && <></>}
          {/* submit button */}
        </div>
        <div className="pb-5 px-5">
          <div className="mx-auto flex justify-between items-center">
            <Button
              onClick={(e) => {
                resetFormAndClose();
              }}
              flat
              className="p-4"
              label="Cancel"
            />

            <Button
              disabled={!isFormSubmittable}
              onClick={submitForm}
              className="p-4"
              label={group ? "Update group" : "Create group"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {file && activeCrop ? (
        <ImageCropModal
          src={file}
          setPicture={setImage}
          activeCrop={activeCrop}
          setActiveCrop={setActiveCrop}
          aspectRatio={16 / 9}
          cropRatioMessage="Banner ratio - 16:9"
        />
      ) : null}
      <UnsplashImagePicker
        active={activeUnSplash}
        setActive={setActiveUnSplash}
        initialPhotoSearchQuery={lang.trans("Group")}
        onPhotoSelect={async (photo) => {
          setActiveUnSplash(false);
          selectFile(photo.blobData);
          setActiveCrop(true);
          setColor("");
          setGroupBanner("");
        }}
      />
    </>
  );
}

export function CurrencySelector({ currency, onChange }) {
  return (
    <>
      <div className="font-semibold mb-1">
        <I18>Currency (Default INR)</I18>
      </div>
      <div className="border py-2 theme-border-default cursor-pointer mb-2 px-2 theme-bg-surface rounded">
        <select
          className="focus:outline-none w-full bg-transparent"
          value={currency}
          onChange={(e) => {
            onChange(e.target.value);
          }}>
          {razorpayCurrencyCodes.map((currency) => (
            <option key={currency.isoCode} value={currency.isoCode}>
              {currency.isoCode} - {currency.currencyName}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const CreateGroupModal = connect(
  (s) => ({
    user: s.auth,
    community: s.community,
    communityPac: s.communityPac,
    groups: s.groups,
  }),
  (d) =>
    bindActionCreators(
      {
        updateGroups: setGroups,
      },
      d
    )
)(CreateGroupModalComponent);

export { CreateGroupModal };
