import { useState } from "react";
import { connect } from "react-redux";
import { ToastTypes } from "../../../contexts/toastr.context";
import { useAppService } from "../../../hooks/use-app-service";
import useLang from "../../../hooks/use-lang.hook";
import { createPostDetailPageRouteFromSlug } from "../../../_pages/post-detail.page";
import { PostService } from "../../../_service";
import {
  highlightPost,
  unHighlightPost,
} from "../../../_store/highlighted-posts.store";
import {
  addSectionPinnedPost,
  removeSectionPinnedPosts,
} from "../../../_store/sections.store";
import { ActionModal } from "../../action-modal.component";
import I18 from "../../atoms/i18";
import IconButton from "../../icon-button.component";
import IconMenu2, { ActionType } from "../../icon-menu-2.component";
import { SharePostModal } from "../../share-post-modal.component";
import UpdatePost from "../edit";
import PostLiveStatus from "./post-live-status.component";

function PostMenuActionsComponent({
  community,
  post,
  updatePost,
  deletePost,
  user,
  group,
  setEmbedModalVisible,
  setIsProcessing,
  addToast,
  // state
  highlightedPostIds,
  // dispatch
  highlightPost,
  unHighlightPost,
  addSectionPinnedPost,
  removeSectionPinnedPosts,
}) {
  // const [dropDownVisible, setDropDownVisible] = useState(false);
  const [isBeingEdit, setIsBeingEdit] = useState(false);

  const { analyticsService } = useAppService();

  // Display model to copy post url if value is set to `true`
  const [isCopyUrlModalVisible, setCopyUrlModalVisible] = useState(false);

  const isSharedPost = post.sharedId != undefined && post.sharedId != null;
  const { id, isBookmarkedByMe, isPinned } = isSharedPost ? post.shared : post;
  const [deleteModal, setDeleteModal] = useState(false);

  const isHighlighted = highlightedPostIds?.includes(post.id);

  const lang = useLang();
  return (
    <>
      <div className="PostMenuActions flex items-center place-content-end flex-1">
        {/* Display post share option only on original post */}
        {user && !post.sharedId && (
          <button
            className="px-2 py-1 rounded text-xs hover:theme-text-primary-light"
            onClick={(e) => {
              setCopyUrlModalVisible(true);
            }}>
            <I18>Share</I18>
          </button>
        )}
        {/* post pin */}
        {isPinned ? (
          <IconButton
            icon="img"
            img="/assets/vectors/pin-light-icon.svg"
            small
            className="theme-bg-primary h-8 w-8"
            onClick={(e) => {
              // check if admin or moderator
              if (group && ["admin", "moderator"].includes(group.myRole)) {
                // unpin the post
                setIsProcessing(true);
                PostService.unpinPost(user, id)
                  .then(({ post }) => {
                    setIsProcessing(false);
                    updatePost(post);
                    removeSectionPinnedPosts(post);
                  })
                  .catch((err) => {
                    setIsProcessing(false);
                    console.log({ err });
                  });
              }
            }}
          />
        ) : (
          <></>
        )}
        {/* live status */}
        <PostLiveStatus post={post} user={user} />
        {/* <span className="mx-2 px-2 cursor-pointer text-xs">Share</span> */}
        {/* show more options only if user logged in */}
        {user ? (
          <>
            {/* more options */}
            <IconMenu2
              dropdownClassName={isBookmarkedByMe ? "w-56" : "w-56"}
              icon="menu-dots"
              hideOnEmpty
              actions={[
                {
                  // Toggle bookmark
                  icon: "bookmark",
                  solidIcon: isBookmarkedByMe,
                  label: isBookmarkedByMe ? "Remove Bookmark" : "Bookmark",
                  onClick: () => {
                    (isBookmarkedByMe
                      ? PostService.removeBookmark(user, id)
                      : PostService.bookmarkPost(user, id)
                    )
                      .then(() => {
                        !isBookmarkedByMe && analyticsService.track("bookmark-post", {
                          postUrl:
                          window.location.origin +
                          createPostDetailPageRouteFromSlug(post)
                        })
                        // bookmark updated, update the post
                        updatePost({
                          ...post,
                          isBookmarkedByMe: !isBookmarkedByMe,
                        });
                        addToast(
                          !isBookmarkedByMe
                            ? "Bookmark added!"
                            : "Bookmark removed",
                          "",
                          !isBookmarkedByMe
                            ? ToastTypes.success
                            : ToastTypes.info
                        );
                      })
                      .catch((error) => {
                        alert(lang.trans("Failed performing action!"));
                      });
                  },
                },
                {
                  // show remove pin button
                  icon: "thumbtack",
                  solidIcon: post.isPinned,
                  label:
                    group && ["admin", "moderator"].includes(group.myRole)
                      ? post.isPinned
                        ? "Remove Pin"
                        : "Pin Post"
                      : "",
                  onClick: (e) => {
                    // unpin the post
                    setIsProcessing(true);
                    (post.isPinned
                      ? PostService.unpinPost(user, post.id)
                      : PostService.pinPost(user, post.id)
                    )
                      .then(({ post }) => {
                        setIsProcessing(false);
                        if (post.isPinned) {
                          addSectionPinnedPost(post);
                        } else {
                          removeSectionPinnedPosts(post);
                        }
                        updatePost(post);
                      })
                      .catch((err) => {
                        setIsProcessing(false);
                        console.log({ err });
                      });
                  },
                },
                {
                  // show remove highlight button
                  icon: "thumbtack",
                  solidIcon: isHighlighted,
                  label:
                    community?.myRole === "admin"
                      ? isHighlighted
                        ? "Remove from featured post"
                        : "Add to featured post"
                      : "",
                  onClick: (e) => {
                    // unpin the post
                    setIsProcessing(true);
                    if (isHighlighted) {
                      // remove highlight
                      unHighlightPost(post.id, (error) => {
                        if (!error) {
                          setIsProcessing(false);
                          addToast("Post removed from featured!");
                        }
                      });
                    } else {
                      // add highlight
                      highlightPost(post.id, (error) => {
                        if (!error) {
                          setIsProcessing(false);
                          addToast("Post set as featured!");
                        }
                      });
                    }
                    /* (false
                                          ? PostService.unpinPost(user, post.id)
                                          : highlightPost(post.id)
                                        )
                                          .then(({ post }) => {
                                            setIsProcessing(false);
                                            updatePost(post);
                                          })
                                          .catch((err) => {
                                            setIsProcessing(false);
                                            console.log({ err });
                                          }); */
                  },
                },
                {
                  // show edit button
                  icon: "edit",
                  label:
                    !isSharedPost && user && post.createdBy.id === user.id
                      ? "Edit"
                      : "",
                  onClick: (e) => {
                    setIsBeingEdit(true);
                  },
                },
                {
                  // show embed button
                  icon: "globe",
                  label: !isSharedPost ? "Embed Post" : "",
                  onClick: () => {
                    setEmbedModalVisible(true);
                  },
                },
                {
                  // show delete button
                  icon: "trash",
                  label: !isSharedPost && post.canDelete ? "Delete" : "",
                  actionType: ActionType.alert,
                  onClick: () => {
                    setDeleteModal(true);
                  },
                },
              ]}></IconMenu2>
          </>
        ) : (
          <></>
        )}
        <ActionModal
          active={deleteModal}
          header="Delete Post"
          title="Are you sure you want to delete the post?"
          setActive={setDeleteModal}
          onSubmit={() => {
            setIsProcessing(true);
            PostService.deletePost(user, post.id)
              .then((response) => {
                // update post
                setIsProcessing(false);
                deletePost(response.post);
                analyticsService.track("delete-post", {
                  groupName: post?.group.name,
                  sectionName: post?.tab.name,
                  postUrl:
                    window.location.origin +
                    createPostDetailPageRouteFromSlug(post),
                });
              })
              .catch((err) => {
                setIsProcessing(false);
              });
          }}
        />
      </div>
      {isBeingEdit ? (
        <UpdatePost
          postToUpdate={post}
          active={isBeingEdit}
          setActive={(val) => {
            // set is being edit to false
            setIsBeingEdit(false);
            // set dropdown visible to false
          }}
          user={user}
          updatePost={updatePost}
        />
      ) : (
        <></>
      )}
      {/* Copy post url modal*/}
      {!post.sharedId && (
        <SharePostModal
          post={post}
          heading="Share"
          text={
            window.location.origin + createPostDetailPageRouteFromSlug(post)
          }
          // text={createPostDetailPageRouteFromSlug(post)}
          active={isCopyUrlModalVisible}
          setActive={setCopyUrlModalVisible}
          onClose={() => {
            //  set is copy url modal visible to false
            setCopyUrlModalVisible(false);
          }}
        />
      )}
    </>
  );
}

const PostMenuActions = connect(
  (s) => ({
    community: s.community,
    highlightedPostIds: s.highlightedPosts.postIds,
  }),
  {
    highlightPost,
    unHighlightPost,
    addSectionPinnedPost,
    removeSectionPinnedPosts,
  }
)(PostMenuActionsComponent);

export default PostMenuActions;
