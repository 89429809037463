import { useState } from "react";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Course } from "../../../../types/course/course.type";
import { Loader } from "../../../../_components";
import { Avatar } from "../../../../_components/avatar.component";
import IconMenu2 from "../../../../_components/icon-menu-2.component";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import { TableBody } from "../../../../_components/molecule/listview.component";
import Pagination from "../../../../_components/pagination.component";
import UIcon from "../../../../_components/uicon-component";
import { CourseService } from "../../../../_service";
import { createUserWallPageRoute } from "../../../user-wall.page";
import { CourseStateType } from "../course-detail.page";

interface MembersBodyProps {
  community: any;
  isLoading: boolean;
  course: Course;
  members: any[];
  totalMembers: number;
  setMembers: (members: any[]) => void;
  currentMemberPage: number;
  setCurrentMemberPage: (page: number) => void;
}
/**
 * Displays the members of the course
 * @param {Object} community - community object
 * @param {boolean} isLoading - true if members are being fetched
 * @param {Object} course - course object
 * @param {Array<*>} members - array of members
 * @param {number} totalMembers - total number of members
 * @param {function} setMembers - function to set members
 * @param {number} currentMemberPage - current page of members
 * @param {function} setCurrentMemberPage - function to set current page of members
 */
export function CourseMembersView({
  community,
  isLoading,
  course,
  members,
  totalMembers,
  setMembers = (_) => {},
  currentMemberPage,
  setCurrentMemberPage,
}: MembersBodyProps) {
  if (course.state == CourseStateType.draft) {
    return (
      <div className="mt-4">
        <AlertStrip
          className="theme-bg-surface mt-4"
          warning
          title="Course is in draft."
          message="Members will be able to join once the course is published."
        />
      </div>
    );
  }
  return (
    <div className="MembersBody flex flex-col px-4 pb-4 theme-bg-surface rounded mt-4 ">
      {/* BODY */}
      <div className="flex flex-col space-y-4  divide-y divide-[var(--theme-border-color)]">
        <div className="mt-2 overflow-x-auto">
          {/* TABLE */}
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="text-sm leading-none font-semibold ">
                <th className="text-left px-1 py-3">Name</th>
                <th className="text-left px-1 py-3">Status</th>
                <th className="text-left px-1"></th>
              </tr>
            </thead>
            <tbody>
              <TableBody
                items={members}
                renderItem={(member, index) => (
                  <MemberRenderItem
                    communityId={community.id}
                    courseId={course.id}
                    member={member}
                    index={index}
                    setMember={(member: any) => {
                      let newMembers = members.map((m) => {
                        if (m.id === member.id) {
                          return member;
                        }
                        return m;
                      });
                      setMembers(newMembers);
                    }}
                    onMemberRemove={(member: any): void => {
                      let newMembers = members.filter(
                        (m) => m.id !== member.id
                      );
                      setMembers(newMembers);
                    }}
                  />
                )}
                // @ts-ignore
                isLoading={isLoading}
                placeholder={
                  <tr>
                    <td className="text-center px-4 pt-6" colSpan={3}>
                      No Member joined this course
                    </td>
                  </tr>
                }
                noItemsElement={
                  <tr>
                    <td className="text-center px-4 pt-6" colSpan={3}>
                      No Member joined this course
                    </td>
                  </tr>
                }
              />
            </tbody>
          </table>
          <Pagination
            className="pagination-bar justify-center"
            currentPage={currentMemberPage}
            totalCount={totalMembers}
            pageSize={10}
            onPageChange={(page: number) => {
              setCurrentMemberPage(page);
            }}
          />
        </div>
      </div>
    </div>
  );
}

interface MemberRenderItemProps {
  communityId: string;
  courseId: string;
  member: any;
  index: number;
  setMember: (member: any) => void;
  onMemberRemove: (member: any) => void;
}
/**
 * Displays course member. contains actions like open user profile, Remove member from course, Accept/Reject member request
 * @param {Object} member - member object
 */
function MemberRenderItem({
  communityId,
  courseId,
  member,
  setMember,
  onMemberRemove = () => {},
}: MemberRenderItemProps) {
  // Accept member request
  const [isLoading, setIsLoading] = useState(false);
  const { addToast, ToastTypes } = useToast();

  // Accept member request
  function AddMember() {
    setIsLoading(true);
    CourseService.addMember(communityId, courseId, member.id)
      .then(({ member }) => {
        addToast("Success", "Member accepted", ToastTypes.success);
        setMember(member);
      })
      .catch(({ response }) => {
        console.error("Error accepting member", response.data);
        addToast("Error", "Error accepting member", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // Reject member request
  function removeMember() {
    setIsLoading(true);
    CourseService.removeMember(communityId, courseId, member.id)
      .then(({ member }) => {
        addToast("Success", "Member removed", ToastTypes.success);
        onMemberRemove(member);
      })
      .catch(({ response }) => {
        console.error("Error removing member", response.data);
        addToast("Error", "Error removing member", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <tr className="">
      <td className="flex items-center py-2 mb-2">
        <div className="flex items-center justify-center">
          <Avatar
            className="cursor-pointer"
            user={member}
            noName={true}
            onClick={(e) => {
              // open the user page
              window.open(createUserWallPageRoute(member.id, "activity"));
            }}
            extraInfo={undefined}
          />
        </div>
        <div className="flex items-center">
          <div className="">
            <div className="text-sm font-medium theme-text-heading-1">
              {member.name}
            </div>
            <div className="text-xs theme-text-subtitle-1">
              {member.email && member.mobile ? (
                <span>
                  {" "}
                  {member.email}&nbsp; | &nbsp; {member.countryCode} &nbsp;
                  {member.mobile}{" "}
                </span>
              ) : member.email ? (
                <span>{member.email}</span>
              ) : member.mobile ? (
                <span>
                  {member.countryCode}
                  &nbsp;
                  {member.mobile}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="px-1 text-sm theme-text-heading-1">
          {member.courseStatus === "requested" ? "Requested" : "Joined"}
        </div>
      </td>
      <td>
        {/* show approval for user, when community needs verification */}
        {member.courseStatus === "requested" ? (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex w-full place-content-end">
                <div className="flex items-center space-x-2">
                  <div
                    className="rounded flex items-center place-content-center h-5 w-5 bg-red-500 hover:shadow-lg hover:scale-125 cursor-pointer"
                    onClick={removeMember}>
                    <UIcon icon="cross" size="xxs" className="text-white" />
                  </div>
                  <div
                    className="rounded flex items-center place-content-center h-5 w-5 bg-green-500 hover:shadow-lg hover:scale-125 cursor-pointer"
                    onClick={AddMember}>
                    <UIcon icon="check" size="xxs" className="text-white" />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <IconMenu2
                actions={[
                  {
                    label: "Remove",
                    onClick: () => {
                      removeMember();
                    },
                  },
                ]}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
}
