import React, { useEffect, useMemo } from "react";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import { GroupService } from "../../_service";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";

interface MembersListModalProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  groupId?: string;
  heading: string;
}

export function MembersListModal({
  active,
  setActive,
  groupId,
  heading,
}: MembersListModalProps): JSX.Element {
  const [members, setMembers] = React.useState<any>({});
  const abortController = useMemo(() => new AbortController(), [groupId]);

  useEffect(() => {
    if (!groupId || !active) return;
    async function fetchMembers(groupId: string) {
      try {
        const members = await GroupService.groupMembers(
          abortController,
          groupId,
          undefined
        );
        setMembers(members);
      } catch (error) {
        console.log(error);
      }
    }
    fetchMembers(groupId);
    return () => {
      abortController.abort();
    };
  }, [abortController, groupId, active]);

  return (
    <>
      <Modal active={active} setActive={setActive} padding={false}>
        <div className="pb-4">
          {/* Heading */}
          <div className="p-1 pl-4 theme-bg-surface flex justify-between items-center space-x-2">
            <span className="font-semibold my-2">{heading}</span>
            <IconButton2
              icon="cross-small"
              solid
              hoverable
              size="2xl"
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          {/* Body */}
          <div className="px-4 font-semibold theme-text-heading-2 my-2 ">
            <div className="overflow-y-auto max-h-[50vh]">
              {!members?.groupMembers && <Loader />}
              {members?.groupMembers?.map((member: any) => (
                <div key={member.id} className="my-4">
                  <Avatar
                    user={member}
                    extraInfo={member.userId ? "@" + member.userId : ""}
                    className=" cursor-pointer"
                    onClick={() => {
                      // open the user page
                      window.open(
                        createUserWallPageRoute(member.id, "activity")
                      );
                    }}
                    tag={
                      member.groupRole === "admin" ? (
                        <div className="theme-theme-bg-primary-light theme-text-primary mx-2 font-semibold text-xxs px-1 rounded">
                          Admin
                        </div>
                      ) : member.groupRole === "moderator" ? (
                        <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                          Moderator
                        </div>
                      ) : member.groupRole === "user" ? (
                        <></>
                      ) : (
                        <></>
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
