import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withToast } from "../../contexts/toastr.context";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import { PostService } from "../../_service";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";

/**
 * Get community subscribers with group role
 * @param {*} param0
 * @returns
 */
function PostLikesComponent({ addToast, post, user, group, community }) {
  // get list of users who like
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areLikesLoaded, setAreLikesLoaded] = useState(false);
  const [isUpvotingUsersListVisible, setIsUpvotingUsersListVisible] =
    useState(false);

  // load the likes list, per post change
  useEffect(() => {
    if (user && isUpvotingUsersListVisible && !areLikesLoaded) {
      setIsLoading(true);
      PostService.getPostLikes(user, post.id)
        .then(({ users }) => {
          setUsers(users);
          setAreLikesLoaded(true);
        })
        .catch((err) => {
          console.log({ err });
          addToast("Likes could not be loaded!");
        })
        .finally((e) => {
          setIsLoading(false);
        });
    }
  }, [user, post, isUpvotingUsersListVisible, areLikesLoaded]);

  return (
    <div className="">
      <div
        style={{
          marginLeft:
            post.likes < 3 ? 24 + post.likes * 10 + "px" : 24 + 3 * 10 + "px",
        }}
        className="cursor-pointer"
        onClick={(e) => {
          setIsUpvotingUsersListVisible(true);
        }}>
        Liked by {post.latestLikes[0].name}{" "}
        {post.likes > 1
          ? ` & ${post.likes - 1} other${post.likes > 2 ? "s" : ""}`
          : ""}
      </div>
      {/* subscriber list */}
      <Modal
        setActive={setIsUpvotingUsersListVisible}
        active={isUpvotingUsersListVisible}
        padding={false}>
        {/* headers */}
        <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
          <span className="font-bold theme-text-subtitle-1">
            {isLoading ? (
              <div className="p-1">
                <Loader />
              </div>
            ) : (
              <>
                {users.length} Upvote{users.length === 1 ? "" : "s"}
              </>
            )}
          </span>
          <IconButton2
            icon="cross"
            size="sm"
            onClick={() => {
              setIsUpvotingUsersListVisible(false);
            }}
          />
        </div>
        {/* body */}
        <div className="p-5 theme-text-subtitle-1 pb-24">
          {/* upvoters list */}
          {users.map((u) => (
            <div
              className="m-3 flex justify-between items-center cursor-pointer"
              key={u.id}>
              <div className="flex items-center justify-center">
                <Avatar
                  user={u}
                  onClick={() => {
                    // open the user page
                    window.open(createUserWallPageRoute(u.id, "activity"));
                  }}
                  tag={
                    u.groupRole === "admin" ? (
                      <div className="bg-blue-50 mx-2 font-semibold text-blue-500 text-xxs px-1 rounded">
                        Admin
                      </div>
                    ) : u.groupRole === "moderator" ? (
                      <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                        Moderator
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  extraInfo={[u.mobile, u.email].filter((e) => e).join(" · ")}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

const PostLikes = withToast(
  connect((s) => ({
    user: s.auth,
    group: s.activeGroup,
    community: s.community,
  }))(PostLikesComponent)
);

export default PostLikes;
