import cx from "classnames";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useToast } from "../../../hooks/use-toast.hook";
import { Button } from "../../../_components";
import { CloseButton } from "../../../_components/activity/close-button.component";
import LazyImage from "../../../_components/atoms/lazy-image";
import TextButton from "../../../_components/atoms/text-button";
import Tooltip from "../../../_components/atoms/tooltip";
import ModalForm from "../../../_components/model2";
import AlertStrip from "../../../_components/molecule/alert-strips";
import { ListView } from "../../../_components/molecule/listview.component";
import SidebarGroupsListShimmerComponent from "../../../_components/shimmers/sidebar/sidebar-group-list-shimmer.component";
import UIcon from "../../../_components/uicon-component";
import { history } from "../../../_config";
import { CourseService } from "../../../_service";
import { Fold } from "../../../_utils/extensions/typescript-utils";
import Validator from "../../../_utils/validator";
import CourseSectionCard from "../course-detail/component/course-section-card";
import {
  CourseJoinStatus,
  CourseStateType,
} from "../course-detail/course-detail.page";
import CreateCourseSection from "../course-detail/create-course-section";
import {
  CourseViewContent,
  CourseViewFooter,
  CourseViewNavbar,
  CourseViewSidebar,
} from "./component/course-view-layout";
import { LessonSummaryEdit } from "./lesson-summary/lesson-summary-edit.component";
import { LessonSummaryView } from "./lesson-summary/lesson-summary-view.component";

export const COURSE_VIEW_PAGE_ROUTE = "/course/view/:id";

/** Function to create route to open lesson summery */
export function createLessonViewPageRoute(courseId, sectionId, lessonId) {
  if (courseId && sectionId && lessonId) {
    return `/course/view/${courseId}?section=${sectionId}&lesson=${lessonId}`;
  } else if (courseId && sectionId) {
    return `/course/view/${courseId}?section=${sectionId}`;
  }
  return `/course/view/${courseId}`;
}

/** Lesson State */
export const LessonState = Object.freeze({
  published: "published",
  draft: "draft",
});

function CourseViewPageComponent({ community, location }) {
  const searchQuery = new URLSearchParams(location.search);
  const sectionId = searchQuery.get("section");
  const lessonId = searchQuery.get("lesson");

  const path = window.location.pathname;
  const pathArray = path.split("/").filter((item) => item !== "");

  let courseId;
  if (Array.isArray(pathArray) && pathArray.length > 1) {
    courseId = pathArray[2];
  }
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  // Course State
  const [course, setCourse] = useState();
  const [section, setSection] = useState({});
  const [lesson, setLesson] = useState({});
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const [viewLessonSummary, setViewLessonSummary] = useState(true);
  const [loadingCourse, setLoadingCourse] = useState(false);

  // View State
  const [rightSidebarVisible, setRightSidebarVisible] = useState(true);

  const { addToast, ToastTypes } = useToast();

  // Initialize section and lessons
  useEffect(() => {
    if (!lessonId || !sectionId || !course) {
      return;
    }

    extractLesson(course);
  }, [lessonId, sectionId]);

  // GET COURSE
  useEffect(() => {
    if (!courseId) {
      return;
    }
    setLoadingCourse(true);
    CourseService.getCourseDetails(community.id, courseId)
      .then(({ course, isAdmin }) => {
        // Remove sections with no lessons
        if (!isAdmin && Validator.hasValue(course.sections)) {
          course.sections = course.sections.filter((s) => {
            return s.lessons && s.lessons.length > 0;
          });
        }
        setCourse(course);
        extractLesson(course);
        setHasWritePermission(isAdmin);
        if (isAdmin) {
          setViewLessonSummary(false);
        }
      })
      .catch((error) => {
        console.error(error);
        addToast("Error", "Course could not be fetched!", ToastTypes.danger);
      })
      .finally(() => {
        setLoadingCourse(false);
      });
  }, [courseId]);

  /**
   * Extract lesson from the course depending on the active section and lesson id
   */
  function extractLesson(course) {
    if (course.sections && course.sections.length > 0) {
      const section = course.sections.find((l) => {
        return l.id === sectionId;
      });
      if (section) {
        // console.log("🚀 ~  section", section);
        setSection(section);
        if (section && section.lessons && section.lessons.length > 0) {
          const lesson = section.lessons.find((l) => {
            return l.id === lessonId;
          });
          if (lesson) {
            setLesson(lesson);
          }
        }
      }
    }
  }

  /** Update section in the course */
  function updateSection(section) {
    if (!section) {
      console.error("Section is not defined");
      return;
    }
    const sections = course.sections.map((s) => {
      if (s.id === section.id) {
        return section;
      }
      return s;
    });
    setCourse({ ...course, sections });
    extractLesson({ ...course, sections });
  }

  /**
   * Update lesson in the section
   * @param {Object} lesson
   * @param {String} sectionId
   */

  function updateLesson(lesson, sectionId) {
    if (!lesson) {
      console.error("Lesson is not defined");
      return;
    }
    const section = course.sections.find((s) => {
      return s.id === sectionId;
    });

    if (section) {
      const lessons = section.lessons.map((l) => {
        if (l.id === lesson.id) {
          return lesson;
        }
        return l;
      });
      updateSection({ ...section, lessons });
    }
  }

  if (
    course &&
    course.state === CourseStateType["closed-free"] &&
    !hasWritePermission &&
    course?.joinStatus !== CourseJoinStatus?.joined
  ) {
    return <ClosedGroupView course={course} />;
  }

  const CheckboxItemsList = [
    "Enable Discussion area",
    "Include in free sample",
  ];
  return (
    <div className="min-h-[100vh]">
      {/* NAVBAR */}
      <CourseViewNavbar>
        <div className="flex items-center gap-2 text-xl font-bold theme-text-heading-1">
          <Link to={"/course"}>
            <CloseButton />
          </Link>
          <h1 className="word-breaker flex-1">{course && course.title}</h1>
          {/* Customize layout */}
          <Tooltip
            label="Toggle sidebar"
            align="bottom"
            className="hidden lg:block p-2 hover:theme-bg-default rounded">
            <div
              className="flex h-4 w-4  border theme-border-heading-1 rounded-[2px] cursor-pointer"
              onClick={() => {
                setRightSidebarVisible(!rightSidebarVisible);
              }}>
              <div
                className={cx("w-[5px] border-r  theme-border-heading-1", {
                  "theme-bg-heading-1": rightSidebarVisible,
                  "": !rightSidebarVisible,
                })}
              />
            </div>
          </Tooltip>
        </div>
      </CourseViewNavbar>

      {/* BODY */}
      <main className="CourseViewPage flex place-content-center mt-[55px] px-2 lg:px-6 xl:px-2 h-[calc(100%-84px)] pb-[100px]">
        {/* LEFT SIDEBAR */}
        <CourseViewSidebar leftSidebar visible={rightSidebarVisible}>
          <div className="flex gap-2 items-center p-3 border-b shadow theme-border-default">
            <UIcon icon="list" />
            <h2 className="text-base font-bold flex-1">Table of Content</h2>
          </div>
          <LessonListView
            community={community}
            course={course}
            isLoading={loadingCourse}
            setCourse={setCourse}
            hasWritePermission={hasWritePermission}
            onLessonDelete={(deletedLesson) => {
              // RELOAD WINDOW IF CURRENT ACTIVE LESSON IS DELETED
              if (lesson && deletedLesson.id === lesson.id) {
                window.location.href = createLessonViewPageRoute(
                  course.id,
                  section.id
                );
              }
            }}
          />
        </CourseViewSidebar>

        {/* LESSON SUMMERY */}
        <CourseViewContent>
          <Fold
            value={viewLessonSummary}
            ifPresent={() => (
              // Display lesson view to enrolled user
              <LessonSummaryView
                isLoading={loadingCourse}
                community={community}
                course={course}
                setCourse={setCourse}
                section={section}
                lesson={lesson}
                hasWritePermission={hasWritePermission}
                updateSection={updateSection}
              />
            )}
            ifAbsent={() => (
              // Display lesson edit to course admin
              <LessonSummaryEdit
                community={community}
                isLoading={loadingCourse}
                course={course}
                setCourse={setCourse}
                section={section}
                lesson={lesson}
                hasWritePermission={hasWritePermission}
                updateSection={updateSection}
              />
            )}
          />
        </CourseViewContent>

        {/* RIGHT SIDEBAR */}
        {/* <CourseViewSidebar rightSidebar>
          <div className="flex flex-col space-y-2  p-4">
            <Label variant="t1" className=" mb-4">
              Additional Settings
            </Label>
            {CheckboxItemsList.map((item, index) => (
              <Checkbox
                className="flex items-center font-extralight"
                key={index}
                onClick={() => {}}>
                {item}
              </Checkbox>
            ))}
          </div>
        </CourseViewSidebar> */}
      </main>

      {/* FOOTER */}
      <CourseViewFooter
        className={cx("", {
          hidden: hasWritePermission || !Validator.hasValue(course),
        })}>
        {!hasWritePermission && (
          <FooterView
            community={community}
            course={course}
            sectionId={sectionId}
            lesson={lesson}
            onLessonUpdate={(lesson) => {
              updateLesson(lesson, sectionId);
            }}
          />
        )}
      </CourseViewFooter>
    </div>
  );
}

function FooterView({ community, course, sectionId, lesson, onLessonUpdate }) {
  const [loading, setLoading] = useState(false);

  // Next/Prev state
  const [nextSection, setNextSection] = useState({});
  const [nextLesson, setNextLesson] = useState({});
  const [prevSection, setPrevSection] = useState({});
  const [prevLesson, setPrevLesson] = useState({});
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  const { addToast, ToastTypes } = useToast();

  // Find next and previous section and lesson
  useEffect(() => {
    if (!Validator.hasValue(course) || !Validator.hasValue(course.sections)) {
      return;
    }

    const lessonId = lesson && lesson.id;
    // Find current section
    const section = course.sections.find((s) => {
      return s.id === sectionId;
    });

    if (section) {
      // Find current lesson
      const lesson = section.lessons.find((l) => {
        return l.id === lessonId;
      });
      if (lesson) {
        const activeLessonIndex = section.lessons.indexOf(lesson);
        const activeSectionIndex = course.sections.indexOf(section);

        // Check if next lesson or section exists
        if (activeLessonIndex < section.lessons.length - 1) {
          setHasNext(true);
          setNextSection(section);
          setNextLesson(section.lessons[activeLessonIndex + 1]);
        } else if (activeSectionIndex < course.sections.length - 1) {
          setHasNext(true);
          setNextSection(course.sections[activeSectionIndex + 1]);
          setNextLesson(course.sections[activeSectionIndex + 1].lessons[0]);
        } else {
          setHasNext(false);
        }
        // Check if previous lesson or section exists
        if (activeLessonIndex > 0) {
          setHasPrev(true);
          setPrevSection(section);
          setPrevLesson(section.lessons[activeLessonIndex - 1]);
        } else if (activeSectionIndex > 0) {
          setHasPrev(true);
          setPrevSection(course.sections[activeSectionIndex - 1]);
          setPrevLesson(
            course.sections[activeSectionIndex - 1].lessons[
              course.sections[activeSectionIndex - 1].lessons.length - 1
            ]
          );
        } else {
          setHasPrev(false);
        }
      }
    }
  }, [course, sectionId, lesson]);

  // Listen for key up and down events.
  // If right arrow key is pressed, go to next lesson.
  // If left arrow key is pressed, go to previous lesson.
  useEffect(() => {
    if (!Validator.hasValue(course)) {
      return;
    }
    function handleKeyDown(e) {
      if (e.key === "ArrowRight") {
        if (hasNext) {
          history.push(
            createLessonViewPageRoute(course.id, nextSection.id, nextLesson.id)
          );
        }
      } else if (e.key === "ArrowLeft") {
        if (hasPrev) {
          history.push(
            createLessonViewPageRoute(course.id, prevSection.id, prevLesson.id)
          );
        }
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    hasNext,
    hasPrev,
    course,
    nextSection,
    nextLesson,
    prevSection,
    prevLesson,
  ]);

  // Mark lesson complete
  function markLessonComplete() {
    if (!lesson) {
      console.error("No lesson found");
      return;
    }
    setLoading(true);
    if (loading) return;
    if (!lesson.isCompleted) {
      CourseService.markLessonComplete(
        community.id,
        course.id,
        sectionId,
        lesson.id
      )
        .then(({ lesson }) => {
          onLessonUpdate({ ...lesson, isCompleted: true });
          addToast("Success", "Lesson marked as complete", ToastTypes.success);
        })
        .catch(({ response }) => {
          console.error(response);
          addToast(
            "Error",
            "Unable to mark lesson as complete",
            ToastTypes.danger
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CourseService.markLessonInComplete(
        community.id,
        course.id,
        sectionId,
        lesson.id
      )
        .then(({ lesson }) => {
          onLessonUpdate({ ...lesson, isCompleted: false });
          addToast(
            "Success",
            "Lesson marked as incomplete",
            ToastTypes.success
          );
        })
        .catch(({ response }) => {
          console.error(response);
          addToast(
            "Error",
            "Unable to mark lesson as incomplete",
            ToastTypes.danger
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  if (!Validator.hasValue(course)) {
    return <div></div>;
  } else {
    return (
      <>
        <div className="flex gap-2  place-content-center items-center">
          <FooterButton
            icon="angle-left"
            prevButton
            to={createLessonViewPageRoute(
              course?.id,
              prevSection?.id,
              prevLesson?.id
            )}
          />
          <FooterButton
            icon="angle-right"
            nextButton
            to={createLessonViewPageRoute(
              course.id,
              nextSection?.id,
              nextLesson?.id
            )}
          />
        </div>
        <Button
          label={
            lesson && lesson.isCompleted ? "Mark incomplete" : "Mark Complete"
          }
          isLoading={loading}
          outlined={lesson.isCompleted}
          onClick={() => {
            markLessonComplete();
          }}
        />
      </>
    );
  }

  function FooterButton({ icon, to, nextButton, prevButton }) {
    return (
      <Link
        to={to}
        className={cx(
          "flex items-center place-content-center p-4 w-8 h-8 border theme-border-primary rounded",
          "hover:shadow-xl ",
          {
            "opacity-30 cursor-not-allowed": !hasNext && nextButton,
          },
          {
            "opacity-30 cursor-not-allowed": !hasPrev && prevButton,
          }
        )}>
        <UIcon icon={icon} size="" className="theme-text-primary h-5" />
      </Link>
    );
  }
}

/** Render list of all available sections and their lessons.
 * @type {React.ComponentType<{}>}
 * @param {Object} community - community object
 * @param {Object} course - course object
 * @param {boolean} isLoading - boolean to check if course is loading
 * @param {Function} setCourse - callback function to set course
 * @param {Function} onLessonDelete - callback function to flag lesson as deleted
 * @param {hasWritePermission} hasWritePermission - boolean to check if user has write permission
 */
function LessonListView({
  community,
  course,
  isLoading,
  setCourse,
  onLessonDelete = () => {},
  hasWritePermission,
}) {
  const [isAddSectionVisible, setIsAddSectionVisible] = React.useState(false);
  return (
    <>
      <div className="flex flex-col space-y-2  theme-bg-surface p-1 ">
        {/* SECTIONS LIST */}
        <ListView
          items={course?.sections}
          loading={isLoading}
          placeholder={
            <div className="flex flex-col gap-2 py-4 pl-2 overflow-y-auto">
              <div className="w-10/12">
                <SidebarGroupsListShimmerComponent />
              </div>
              <SidebarGroupsListShimmerComponent />
            </div>
          }
          noItemsElement={
            hasWritePermission ? (
              <>
                <AlertStrip
                  warning
                  title="No content added"
                  message="Start adding section by clicking on below button"
                />
              </>
            ) : (
              <>
                <AlertStrip
                  warning
                  title="No content available"
                  message="Looks like course author has not added any content yet."
                />
              </>
            )
          }
          renderItem={(section, index) => (
            <CourseSectionCard
              key={section.id}
              course={course}
              setCourse={setCourse}
              onSectionUpdate={(e) => {
                const sectionList = course.sections;
                sectionList[index] = e;
                setCourse({ ...course, sections: sectionList });
              }}
              onSectionDelete={(deletedSection) => {
                const sectionList = course.sections.filter(
                  (section) => section.id !== deletedSection.id
                );
                setCourse({ ...course, sections: sectionList });
              }}
              onLessonDelete={(deletedLesson) => {
                const sectionList = course.sections;
                sectionList[index].lessons = sectionList[index].lessons.filter(
                  (lesson) => lesson.id !== deletedLesson.id
                );
                setCourse({ ...course, sections: sectionList });
                onLessonDelete(deletedLesson);
              }}
              onLessonAdded={(lesson) => {
                const sectionList = course.sections;
                const lessonList = sectionList[index].lessons ?? [];
                lessonList.push(lesson);
                sectionList[index].lessons = lessonList;
                setCourse({ ...course, sections: sectionList });
              }}
              section={section}
              community={community}
              hasWritePermission={hasWritePermission}
              className="theme-bg-surface"
            />
          )}
        />
        {hasWritePermission && (
          <ModalForm
            title={"Add new section"}
            className="w-full sm:w-2/3 md:w-2/4 lg:w-1/3"
            visible={isAddSectionVisible}
            setVisible={setIsAddSectionVisible}
            button={
              <TextButton
                label="Add section"
                className="outline-1 outline-dashed w-11/12 mx-2"
                onClick={() => setIsAddSectionVisible(true)}
              />
            }>
            <CreateCourseSection
              community={community}
              course={course}
              setCourse={(course) => {
                setCourse(course);
                setIsAddSectionVisible(false);
              }}
              setVisible={setIsAddSectionVisible}
            />
          </ModalForm>
        )}
      </div>
    </>
  );
}

/**
 * Closed group view for not joined user
 */
function ClosedGroupView({ course }) {
  return (
    <div className="flex flex-col items-center max-w-3xl mx-auto w-full h-screen">
      <div className="flex flex-col items-center justify-center gap-4 mt-20">
        <div className="theme-bg-surface border theme-border-default rounded ">
          <div
            className="theme-bg-primary  object-cover w-full max-w-3xl"
            style={{
              ...{
                backgroundColor: "var(--theme-primary-light-color)",
                aspectRatio: "16/6",
              },
            }}>
            {course?.banner ? (
              <LazyImage
                src={course?.banner}
                className="rounded-t"
                alt="banner"
              />
            ) : (
              <div className="w-full"></div>
            )}
          </div>
          <div className="flex flex-col space-y-2 p-2">
            <h3 className="font-semibold theme-text-heading-1">
              {course.title}
            </h3>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="text-center">
            You need to join the course to view the content.
          </p>
          <Link
            to={`/course/${course?.id}`}
            className="px-4 py-2 rounded-full theme-bg-default  ">
            Join Course
          </Link>
        </div>
      </div>
    </div>
  );
}

const stp = (state) => {
  return {
    community: state.community,
  };
};

const dtp = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const CourseViewPage = withRouter(connect(stp, dtp)(CourseViewPageComponent));

export default CourseViewPage;
