import { useState } from "react";
import { history } from "../../../_config";
import { createUserWallPageRoute } from "../../../_pages/user-wall.page";
import Validator from "../../../_utils/validator";
import { CloseButton } from "../../activity/close-button.component";
import { Avatar } from "../../avatar.component";
import { ListView } from "../../molecule/listview.component";
import Label from "../../typography/label.typography";

/**
 * Component to display poll result
 * @param {Object} post
 * @param {Function} setDisplayStatics - function to close the statics modal
 * @returns
 */
export default function PollResult({ post, setDisplayStatics = () => {} }) {
  const isSharedPost = Validator.hasValue(post.shared);
  const poll = isSharedPost ? post.shared.poll : post.poll;
  const result =
    poll &&
    poll.answers &&
    poll.answers.map((answer) => {
      return {
        pollOption: answer.submissions[0],
        user: answer.user,
      };
    });

  const formatted =
    result &&
    result.reduce((acc, curr) => {
      const key = curr.pollOption.option;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr.user);
      return { ...acc, [key]: acc[key] };
    }, {});
  return (
    <div className="PollResult flex flex-col gpa-2 theme-bg-default">
      <div className="flex items-center py-2 px-4">
        <Label size="h3" variant="t1" translate className="flex-1">
          Poll Result
        </Label>
        <CloseButton
          onClick={() => {
            setDisplayStatics(false);
          }}
        />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <Label size="h3" variant="t2" className="px-4">
          {poll.questions[0].statement}
        </Label>
        <div className="flex flex-col gap-1 w-full theme-bg-surface p-2">
          <Label size="" variant="t2" className="p-2 px-4">
            {poll.voteCount} votes
          </Label>
          <ListView
            items={poll.questions[0].options}
            className="PollOptions flex flex-col gap-3 w-full px-4"
            renderItem={(option, index) => (
              <PollResultSubmission
                poll={poll}
                option={option}
                users={formatted && formatted[option]}
                index={index}
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  /**
   * Display poll option and users who voted for it
   * @param {*} param0
   * @returns
   */
  function PollResultSubmission({ poll, option, index, users }) {
    const [isExpand, setIsExpand] = useState(true);
    return (
      <div className="border theme-border-default rounded">
        <div
          className="flex items-center w-full rounded theme-bg-default  hover:theme-bg-surface cursor-pointer p-3"
          onClick={() => {
            setIsExpand(!isExpand);
          }}>
          <Label variant="t2">{index + 1}.&nbsp;&nbsp;</Label>
          <Label className="flex-1">{option}</Label>

          <Label variant="t2">
            {Math.floor(poll.votes[option] * poll.voteCount)} &nbsp; Members
          </Label>
        </div>
        {isExpand && (
          <ListView
            items={users}
            className="flex flex-col gap-1 w-full divide-y divide-[var(--theme-border-color)] cursor-pointer"
            renderItem={(user) => (
              <div className="p-2">
                <Avatar
                  user={user}
                  onClick={() => {
                    history.push(createUserWallPageRoute(user.id));
                  }}
                />
              </div>
            )}
          />
        )}
      </div>
    );
  }
}
