import cx from "classnames";
import React from "react";
import { connect } from "react-redux";
import removeMd from "remove-markdown";
import styled from "styled-components";
import { useAppService } from "../../hooks/use-app-service";
import { history } from "../../_config";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import { getHighlightedPosts } from "../../_store/highlighted-posts.store";
import { Avatar } from "../avatar.component";
import { Heading1 } from "../typography";
import UIcon from "../uicon-component";

const CARD_WIDTH = 210;

function HighlightedPostsComponent({ highlightedPosts }) {
  const { posts } = highlightedPosts;

  const sliderRef = React.createRef();

  const sliderWidth = posts.length * CARD_WIDTH + (posts.length - 1) * 16;

  const scrollToNextCard = () => {
    const scrollViewWidth = sliderRef.current.getBoundingClientRect().width;
    const scrolled = sliderRef.current.scrollLeft;
    const totalToScroll = sliderRef.current.scrollWidth;
    let newScrollLeft = 0;
    if (scrolled + scrollViewWidth < totalToScroll) {
      newScrollLeft = scrolled + scrollViewWidth;
    }
    sliderRef.current.scrollTo({
      top: 0,
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  const scrollToPrevCard = () => {
    const scrollViewWidth = sliderRef.current.getBoundingClientRect().width;
    const scrolled = sliderRef.current.scrollLeft;
    const totalToScroll = sliderRef.current.scrollWidth;
    let newScrollLeft = 0;
    if (scrolled === 0) {
      newScrollLeft = totalToScroll;
    } else if (scrolled - scrollViewWidth > 0) {
      newScrollLeft = scrolled - scrollViewWidth;
    }
    sliderRef.current.scrollTo({
      top: 0,
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  if (!posts || posts.length === 0) {
    return <></>;
  }

  return (
    <HighlightedPostsHolder>
      {/* header */}
      <div className="flex items-center justify-between mb-2">
        {/* heading */}
        <Heading1 text="Featured" />
        {/* buttons */}
        <div className="flex">
          <NavigationButton icon="left" onClick={scrollToPrevCard} />
          <NavigationButton onClick={scrollToNextCard} />
        </div>
      </div>
      {/* posts */}
      <SliderWrapper
        ref={sliderRef}
        className="max-w-[370px] sm:max-w-xl xl:max-w-2xl">
        <HighlightedPostTileContainer
          className="theme-bg-surface p-3 rounded"
          style={{
            width: sliderWidth + "px",
          }}>
          {posts.map((post) => (
            <HighlightedPostTile key={post.id} post={post} />
          ))}
        </HighlightedPostTileContainer>
      </SliderWrapper>
    </HighlightedPostsHolder>
  );
}

const HighlightedPostsHolder = styled.div`
  margin-bottom: 1rem;
  max-width: 640px;
`;

function NavigationButton({ icon = "right", onClick = () => {} }) {
  return (
    <NavigationButtonWrapper
      className="border rounded-lg theme-bg-surface cursor-pointer theme-border-default hover:theme-border-subtitle-2 hover:scale-110 transition-all duration-200"
      onClick={onClick}>
      <UIcon
        icon={"angle-" + icon}
        className="theme-text-subtitle-2 hover:theme-text-heading-2"
      />
    </NavigationButtonWrapper>
  );
}

const NavigationButtonWrapper = styled.button`
  padding: 1px 6px;
  :first-child {
    margin-right: 5px;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HighlightedPostTileContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  min-width: 100%;
`;

function HighlightedPostTile({ post }) {
  const { analyticsService } = useAppService();
  // check if we have media
  const media = post.banner
    ? post.banner
    : post.images?.length
    ? post.images[0]
    : null;

  const { title, description, createdBy } = post;

  const descriptionCutoff = media ? 50 : 115;

  const trimmedTitle = title?.length > 50 ? title.slice(0, 45) + "..." : title;
  const trimmedDescription =
    description?.length > descriptionCutoff
      ? removeMd(description).slice(0, descriptionCutoff - 4) + "..."
      : removeMd(description);
  return (
    <HighlightedPostTileWrapper
      className="theme-bg-surface rounded-md overflow-hidden border theme-border-default"
      onClick={() => {
        history.push(createPostDetailPageRouteFromSlug(post));
        analyticsService.track("featured-post-clicked", {
          postUrl:
            window.location.origin + createPostDetailPageRouteFromSlug(post),
        });
      }}>
      <div className="flex-grow">
        {media ? (
          <div className="relative">
            <img src={media} className="flex-none" alt="banner" />
          </div>
        ) : (
          <></>
        )}
        {trimmedTitle ? <div className="px-4 pt-2">{trimmedTitle}</div> : <></>}
        {trimmedDescription && ((media && !trimmedTitle) || !media) ? (
          <div
            className={cx("px-4 pb-2 theme-text-subtitle-2", {
              "pt-2": !media && !trimmedTitle,
            })}>
            {trimmedDescription}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="px-3 pb-3">
        <Avatar user={createdBy} />
      </div>
    </HighlightedPostTileWrapper>
  );
}

const HighlightedPostTileWrapper = styled.div`
  width: ${CARD_WIDTH}px;
  height: ${CARD_WIDTH}px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0px 15px 0px rgb(0 0 0 / 0.1), 0 0px 15px 0px rgb(0 0 0 / 0.1);
  }
`;

const stp = (s) => ({
  user: s.auth,
  community: s.community,
  highlightedPosts: s.highlightedPosts,
});

export default connect(stp, {
  getHighlightedPosts,
})(HighlightedPostsComponent);
