import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Remarkable } from "remarkable";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { useAppDispatch } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import useLang from "../../hooks/use-lang.hook";
import { useToast } from "../../hooks/use-toast.hook";
import { Button, PostCardList } from "../../_components";
import { ActionModal } from "../../_components/action-modal.component";
import { ActivityCardList } from "../../_components/activity-card-list.component";
import I18 from "../../_components/atoms/i18";
import LazyImage from "../../_components/atoms/lazy-image";
import TextButton from "../../_components/atoms/text-button";
import KarmaPointsHeaderButton from "../../_components/header.component/karma-points-button";
import IconButton from "../../_components/icon-button.component";
import IconMenu2 from "../../_components/icon-menu-2.component";
import { ProfileUpdate } from "../../_components/profile/profile-update.component";
import PostListShimmer from "../../_components/shimmers/post/post-list-shimmer.component";
import UserProfileHeaderShimmer from "../../_components/shimmers/user-profile-header-shimmer.component";
import Tab from "../../_components/tab.component";
import Label from "../../_components/typography/label.typography";
import { UserWallSocial } from "../../_components/user-wall-social.component";
import { history } from "../../_config";
import { redirectToAuthUrl } from "../../_config/helper.config";
import { CommunityService, PostService, UserService } from "../../_service";
import { setCommunity } from "../../_store/_actions/community.actions";
import { USER_PROFILE_UPDATE_PAGE_ROUTE } from "../community-settings/profile-update.page";
import UserMembershipCard from "./user-membership-card";
import UserWallNFTTab from "./user-wall-nfts.tab";

export const USER_WALL_PAGE_ROUTE = "/user/:userId";

export const DIRECT_MESSAGE_PAGE = (userId) => `/direct-message/${userId}`;

export function createUserWallPageRoute(userId, tabName = "activity") {
  return `/user/${userId}?tab=` + tabName;
}

export function createUserWallPageUrl(userId, tabName = "activity") {
  return window.location.origin + createUserWallPageRoute(userId, tabName);
}

export const TabType = Object.freeze({
  activity: "activity",
  post: "posts",
  bookmark: "bookmark",
  nfts: "nfts",
});
export default function UserWallPageComponent({
  user,
  community,
  mySubscription,
}) {
  const md = new Remarkable();
  const lang = useLang();
  const { analyticsService } = useAppService();

  const dispatch = useAppDispatch();

  // get user id
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [isUserprofileLoading, setIsUserprofileLoading] = useState(true);
  const [isEditProfileVisible, setEditProfileVisible] = useState(false);

  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisible] =
    useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const tag = urlParams.get("tab");

  // holds the selected currentPage
  const [selectedTab, setSelectedTab] = useState(tag ?? TabType.post); // can have posts/bookmarks
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const [currentActivityPage, setCurrentActivityPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [activities, setActivities] = useState([]);

  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);
  const [isLoadingBookmarkedPosts, setIsLoadingBookmarkedPosts] =
    useState(false);
  // helps with pagination
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false);
  const [hasMorePost, seHasMorePosts] = useState(true);
  const [isLoadingMoreActivities, setIsLoadingMoreActivities] = useState(false);
  const [noMoreActivities, setNoMoreActivities] = useState(false);

  const [imageToBePreviewed, setImageToBePreviewed] = useState(null);

  const [communityKarmaScore, setCommunityKarmaScore] = useState("");

  const [walletAddress, setWalletAddress] = useState(null);

  const isMyProfile = userDetails?.id === user?.id;
  const communityId = community?.id;

  const { addToast } = useToast();

  // fetch user posts and details
  useEffect(() => {
    if (userId && communityId) {
      // get user posts
      CommunityService.getUserPostsInCommunityById(communityId, userId).then(
        ({ posts, page }) => {
          if (posts && posts.length > 0) {
            posts = posts.map((post) => {
              return {
                ...post,
                comments: post.comments ?? [],
              };
            });
          }
          setPosts(posts);
          setIsLoadingPosts(false);
        }
      );
      // Get user Activities
      CommunityService.getUserActivitiesInCommunityById(communityId, userId)
        .then(({ userActivities, page }) => {
          setActivities(userActivities);
          setIsLoadingActivity(false);
        })
        .catch((error) => {
          // addToast("Something went wrong while loading posts");
          console.log({ error });
          setNoMoreActivities(true);
          setIsLoadingActivity(false);
        });
    }
  }, [communityId, userId]);

  // Get user profile
  useEffect(() => {
    if (userId) {
      UserService.getUserProfileById(userId).then((response) => {
        setUserDetails(response.user);
        setIsUserprofileLoading(false);
      });
    }
  }, [userId]);

  // get user bookmarks, only if for auth user
  useEffect(() => {
    if (isMyProfile) {
      setIsLoadingBookmarkedPosts(true);
      PostService.getBookmarks().then(({ posts }) => {
        setBookmarkedPosts(posts);
        setIsLoadingBookmarkedPosts(false);
      });
    }
  }, [isMyProfile]);

  useEffect(() => {
    history.replace({
      pathname: `/user/${userId}`,
      search: `?tab=${selectedTab}`,
    });
  }, [selectedTab, userId]);

  // post lazy loading
  useEffect(() => {
    // create callback
    const callBack = () => {
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        // you're at the bottom of the page
        // do this when we reach end
        // and are viewing posts and not bookmark
        if (
          !isLoadingMorePosts &&
          hasMorePost &&
          selectedTab === TabType.post
        ) {
          // if we are not already loading more posts, load more posts
          setIsLoadingMorePosts(true);
          CommunityService.getUserPostsInCommunityById(
            communityId,
            userId,
            currentPostPage + 1
          ).then((response) => {
            // if there are no more posts, set so
            if (response.posts.length === 0 || response.posts.length < 10) {
              seHasMorePosts(false);
            } else {
              let newList = response.posts;
              if (newList && newList.length > 0) {
                newList = newList.map((post) => {
                  return {
                    ...post,
                    comments: post.comments ?? [],
                  };
                });
              }
              setPosts([...posts, ...newList]);
              setCurrentPostPage(response.page);
            }
            setIsLoadingMorePosts(false);
          });
        }
        if (
          !isLoadingMoreActivities &&
          !noMoreActivities &&
          selectedTab === TabType.activity
        ) {
          // if we are not already loading more posts, load more posts
          setIsLoadingMoreActivities(true);
          CommunityService.getUserActivitiesInCommunityById(
            communityId,
            userId,
            currentActivityPage + 1
          ).then((response) => {
            // if there are no more userActivities, set so
            if (response.userActivities.length === 0) {
              setNoMoreActivities(true);
            } else {
              setActivities([...activities, ...response.userActivities]);
              setCurrentActivityPage(response.page);
              setIsLoadingMoreActivities(false);
            }
            setIsLoadingMoreActivities(false);
          });
        }
      }
    };

    window.addEventListener("scroll", callBack);

    return () => {
      window.removeEventListener("scroll", callBack);
    };
  }, [
    isLoadingMorePosts,
    isLoadingMoreActivities,
    currentPostPage,
    posts,
    activities,
    hasMorePost,
    userId,
    communityId,
    selectedTab,
    noMoreActivities,
    currentActivityPage,
  ]);

  useEffect(() => {
    const cb = (e) => {
      setImageToBePreviewed(e.target.src);
    };
    document.querySelectorAll("#userProfilePicture").forEach((img) => {
      img.addEventListener("click", cb);
    });

    return () => {
      document.querySelectorAll("#userProfilePicture").forEach((img) => {
        img.removeEventListener("click", cb);
      });
    };
  }, [posts]);

  // TODO: update both leasts when one is updated

  const onSubmitUnsubscribe = () => {
    //  unsub community
    CommunityService.leaveCommunity(user, community.id)
      .then(({ community }) => {
        // update community
        // updateCommunity(community);
        dispatch(setCommunity(community));
        setIsUnsubscribeModalVisible(false);
        // show message
        addToast(lang.trans("Unsubscribed from") + " " + community.name);
      })
      .catch((error) => {
        console.log({ error, message: "Subscribe to community failed!" });
        addToast(
          lang.trans("Could not unsubscribe from") + " " + community.name,
          "",
          ToastTypes.danger
        );
      });
  };

  useEffect(() => {
    if ((communityId, isMyProfile)) {
      // get community
      CommunityService.getMyLeaderboardScore(communityId)
        .then((response) => {
          setCommunityKarmaScore(response.score);
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [communityId, isMyProfile]);

  if (!user) {
    return (
      <div className="PostCard flex flex-col items-center justify-center">
        <div className="text-center my-5">
          <I18>Login to view this page</I18>
        </div>
        <Button
          label="Login"
          onClick={(e) => {
            redirectToAuthUrl(community);
          }}
        />
      </div>
    );
  }
  return (
    <>
      <div className="UserWallPage">
        {Object.keys(user).length > 0 && (
          <ProfileUpdate
            userDetails={userDetails}
            visible={isEditProfileVisible}
            setVisible={setEditProfileVisible}
          />
        )}
        {imageToBePreviewed && (
          <div
            className="UserProfilePicFullPreview"
            onClick={(e) => {
              setImageToBePreviewed(null);
            }}>
            <div className="holder">
              <img src={imageToBePreviewed} alt="Preview" />
              <IconButton
                icon="plus"
                rotate={45}
                className="close-button"
                onClick={(e) => {
                  setImageToBePreviewed(null);
                }}
              />
            </div>
          </div>
        )}

        <div className="md:left-3 md:max-w-[720px]">
          {/* user details */}
          {isUserprofileLoading ? (
            <UserProfileHeaderShimmer />
          ) : (
            <div className="user-detail-card theme-bg-surface shadow">
              {/* details */}
              {/* edit / follow button */}
              <div className="flex flex-row justify-between ">
                <div className="flex justify-start w-4/12">
                  <div className="flex flex-col sm:flex-row justify-start p-4">
                    <div className="flex min-w-max items-center sm:items-start gap-4">
                      <LazyImage
                        id="userProfilePicture"
                        src={userDetails.picture}
                        alt=""
                        className="w-20 h-20 sm:w-28 sm:h-28 flex-shrink-0 border theme-bg-default object-cover rounded-full"
                      />
                      <div className="sm:hidden pt-2 break-words">
                        <div className="flx flex-col ">
                          <div className="text-lg font-bold">
                            {userDetails.name}
                          </div>
                          <div className="text-xs font-bold ">
                            {userDetails?.shortBio}
                          </div>
                        </div>
                        {isMyProfile && (
                          <Link to={USER_PROFILE_UPDATE_PAGE_ROUTE}>
                            <TextButton
                              className="flex sm:hidden -ml-2"
                              label="Edit Profile"
                              onClick={() => {
                                analyticsService.track("edit-profile-clicked-on-profile")
                              }}
                            />
                          </Link>
                        )}
                        <div className="theme-text-primary font-semibold text-lg flex items-center">
                          {showTier(userDetails.dealTier)}
                          {userDetails.dealTier ? (
                            <div className="text-xs font-light theme-text-subtitle-2 px-2">
                              ({userDetails.dealCount} <I18>deals left</I18>)
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pl-4 pt-2">
                      <div className="hidden sm:inline-block text-xl font-bold">
                        <div>
                          {userDetails.name}
                          <div className="text-xs font-bold ">
                            {userDetails?.shortBio}
                          </div>
                          <div className="text-sm font-normal my-1">
                            @{userDetails.userId}
                          </div>
                        </div>
                        {/* wallet address */}
                        {(community.myRole === "admin" ||
                          user.id === userDetails.id) && (
                          <div className="text-sm font-normal flex-row flex w-[40vw]">
                            {walletAddress && (
                              <>
                                <Label translate>
                                  Wallet Address : {walletAddress}
                                </Label>
                              </>
                            )}
                          </div>
                        )}

                        {/* community karma */}
                        {user.id === userDetails.id && (
                          <div className="flex text-xs">
                            <KarmaPointsHeaderButton
                              communityKarmaScore={communityKarmaScore}
                            />
                          </div>
                        )}
                      </div>
                      <div className="hidden sm:flex theme-text-primary font-semibold text-lg items-center">
                        {showTier(userDetails.dealTier)}
                        {userDetails.dealTier ? (
                          <div className="text-xs font-light theme-text-subtitle-2 px-2">
                            ({userDetails.dealCount} <I18>deals left</I18>)
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-4 md:w-11/12">
                        <div
                          className="NL2BR headings break-all md:max-w-[550px]"
                          dangerouslySetInnerHTML={{
                            __html: md.render(userDetails.bio),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 pr-2 justify-evenly ">
                  {/* Direct message  */}
                  {!isMyProfile && !community.isDirectMessageDisabled && (
                    <IconButton
                      icon="message"
                      onClick={(e) => {
                        analyticsService.track("dm-icon-clicked-on-profile")
                        window.open(DIRECT_MESSAGE_PAGE(userId));
                      }}
                    />
                  )}

                  {isMyProfile && (
                    <Link to={USER_PROFILE_UPDATE_PAGE_ROUTE}>
                      <TextButton
                        className="mt-1.5 w-[90px]  hidden sm:flex"
                        label="Edit Profile"
                        onClick={() => {
                          analyticsService.track("edit-profile-clicked-on-profile")
                        }}
                      />
                    </Link>
                  )}

                  {isMyProfile && (
                    <IconMenu2
                      className="ml-2"
                      actions={[
                        {
                          icon: "sign-out",
                          label:
                            community &&
                            community.joined &&
                            !community.configuration.hideCommunityUnsubscribe
                              ? "Unsubscribe"
                              : "",
                          labelClass: "theme-text-danger",
                          onClick: (r, closeMenu) => {
                            if (closeMenu) {
                              closeMenu(false);
                            }
                            analyticsService.track("unsubscribe-clicked")
                            setIsUnsubscribeModalVisible(true);
                          },
                          actionType: "alert",
                        },
                      ]}
                    />
                  )}
                </div>
              </div>

              <div className="sm:ml-36 mr-6">
                <UserWallSocial userDetails={userDetails} />
              </div>
              <div className="border-b theme-border-default" />
              {/* tabs */}
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                isMyProfile={isMyProfile}
              />
            </div>
          )}

          {/* Show Activity */}
          {selectedTab === TabType.activity && (
            <ActivityCardList
              isLoadingPosts={isLoadingActivity}
              isLoadingMorePosts={isLoadingMoreActivities}
              activities={activities}
              updatePost={(post) => {
                const index = activities.findIndex((p) => p.id === post.id);
                if (index !== -1) {
                  const newPosts = [...activities];
                  newPosts[index] = post;
                  setActivities(newPosts);
                }
              }}
            />
          )}

          {/* Show Posts */}
          {selectedTab === TabType.post && ( // show posts
            <>
              <PostCardList
                posts={posts}
                allPosts={posts}
                setPosts={setPosts}
                isCommunityPost={true}
                isLoading={isLoadingPosts}
                onPostUpdate={(_, list) => {
                  if (list) {
                    setPosts(list);
                  }
                }}
                placeholder={
                  <div className="theme-bg-surface mt-2 rounded">
                    <PostListShimmer length={7} />
                  </div>
                }
                footerElement={
                  <>
                    {isLoadingMorePosts && (
                      <div className="theme-bg-surface mt-2 rounded">
                        <PostListShimmer length={7} />
                      </div>
                    )}
                    {!hasMorePost && (
                      <div className="h-32 flex items-center place-content-center theme-text-subtitle-1 text-xs">
                        <I18>
                          Looks like you have reached the end of the list, No
                          more post to display
                        </I18>
                      </div>
                    )}
                  </>
                }
                noItemsElement={
                  <div className="no-post p-2 my-10 text-center">
                    <div className="font-bold my-3">
                      <I18>Looks like it's lonely here.</I18>
                    </div>
                    <div>
                      <I18>The user hasn't posted anything yet publicly.</I18>
                    </div>
                  </div>
                }
              />
            </>
          )}

          {selectedTab === TabType.bookmark && isMyProfile && (
            // show bookmarks
            <PostCardList
              posts={bookmarkedPosts.filter((p) => p.isBookmarkedByMe)}
              allPosts={bookmarkedPosts}
              isCommunityPost={true}
              setPosts={setBookmarkedPosts}
              isLoading={isLoadingBookmarkedPosts}
              onPostUpdate={(_, list) => {
                if (list) {
                  setBookmarkedPosts(list);
                }
              }}
              placeholder={
                <div className="theme-bg-surface mt-2 rounded">
                  <PostListShimmer length={7} />
                </div>
              }
              footerElement={
                isLoadingMorePosts && (
                  <div className="theme-bg-surface mt-2 rounded">
                    <PostListShimmer length={7} />
                  </div>
                )
              }
              noItemsElement={
                <div className="no-post p-2 my-10 text-center">
                  <div className="font-bold my-3">
                    <I18>Looks like it's lonely here.</I18>
                  </div>
                  <div>
                    <I18>You have no bookmarks right now.</I18>
                  </div>
                </div>
              }
            />
          )}

          {/* nft tab */}
          {/* {VALID_WEB3_HOST.includes(window.location.hostname) && (
            <UserWallNFTTab
              isVisible={TabType.nfts === selectedTab}
              user={userDetails}
              setWalletAddress={setWalletAddress}
            />
          )} */}
        </div>

        {/* Delete tab model */}
        <ActionModal
          active={isUnsubscribeModalVisible}
          setActive={setIsUnsubscribeModalVisible}
          onSubmit={onSubmitUnsubscribe}
          btnColor="red"
          header="Unsubscribe"
          title="Are you sure you want to unsubscribe the community?"
          // isLoading={isLoading}
        />
      </div>
      <UserMembershipCard subscription={mySubscription} />
    </>
  );
}

/**
 * Create the tab view
 * @param {*} param0
 * @returns
 */
function Tabs({ selectedTab, setSelectedTab = (e) => {}, isMyProfile }) {
  return (
    <div className="flex justify-start tab-holder px-5 mt-6">
      <Tab
        onClick={(e) => {
          if (selectedTab !== TabType.activity) {
            setSelectedTab(TabType.activity);
          }
        }}
        tab="Activity"
        selected={selectedTab === TabType.activity}
      />
      <Tab
        onClick={(e) => {
          if (selectedTab !== TabType.post) {
            setSelectedTab(TabType.post);
          }
        }}
        tab="Posts"
        selected={selectedTab === TabType.post}
      />
      {isMyProfile && (
        <Tab
          onClick={(e) => {
            if (selectedTab !== TabType.bookmark) {
              setSelectedTab(TabType.bookmark);
            }
          }}
          tab="Bookmarks"
          selected={selectedTab === TabType.bookmark}
        />
      )}

    </div>
  );
}

function showTier(dealTier) {
  switch (dealTier) {
    case "pensil_tier1":
      return "AppSumo Tier 1";
    case "pensil_tier2":
      return "AppSumo Tier 2";
    case "pensil_tier3":
      return "AppSumo Tier 3";
    default:
      return "";
  }
}

const UserWallPage = withToast(
  connect((s) => ({
    community: s.community,
    user: s.auth,
    mySubscription: s.mySubscription,
  }))(UserWallPageComponent)
);

export { UserWallPage };
