import { COMMUNITY_SUBSCRIPTION_PAGE_ROUTE } from "../../_pages/community-settings/community-subscription.page";
import { Button } from "../button.component";
import IconButton2 from "../icon-button-2-component";
import { Modal } from "../modal.component";

/**
 * @param {any} setActive - function to set the active state of the modal
 * @description - Component to display the upgrade plan content
 */
export function UpgradePlanComponent({ setActive }) {
  return (
    <div className="flex flex-col items-center justify-center theme-bg-surface pb-6 select-none">
      {setActive ? (
        <div className="flex justify-end w-full">
          <IconButton2
            icon="cross"
            size="xs"
            hoverable
            iconClass="theme-text-subtitle-2 mt-1 mr-2 "
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
      ) : (
        <div className="mt-6"></div>
      )}

      <header className=" mx-6">
        <h1 className="text-xl font-bold text-center ">
          Upgrade your community to a paid plan to unlock
        </h1>
        <p className="text-md mt-6 mb-4 text-center">
          To upgrade your plan,{" "}
          <span
            className="theme-text-primary cursor-pointer"
            onClick={() => {
              setActive(false);
              window.location.href = COMMUNITY_SUBSCRIPTION_PAGE_ROUTE;
            }}>
            Click here
          </span>
        </p>
      </header>
      <section className="px-6 pb-6 w-full">
        <ul className="list-disc w-full theme-bg-default rounded border theme-border-default">
          <li>Live Session</li>
          <li> Custom domain </li>
          <li> API access</li>
          <li> Advanced analytics </li>
          <li> Custom CSS</li>
          <li> Single Sign-on (SSO) with WordPress and Memberful</li>
          <li> 500MB limit per file attachment</li>
          <li> 100GB total storage limit across all file attachments</li>
        </ul>
      </section>
      <footer>
        <Button
          label="Choose Plan"
          onClick={() => {
            if (setActive) {
              setActive(false);
            }
            window.location.href = COMMUNITY_SUBSCRIPTION_PAGE_ROUTE;
          }}
        />
      </footer>
    </div>
  );
}

/**
 * @param {any} active - boolean to set the active state of the modal
 * @param {any} setActive - function to set the active state of the modal
 * @description - Component to display the upgrade plan modal
 */
function UpgradePlanModal({ active, setActive }) {
  return (
    <Modal
      active={active}
      setActive={setActive}
      padding={false}
      className={" theme-bg-surface "}>
      <UpgradePlanComponent setActive={setActive} />
    </Modal>
  );
}

export default UpgradePlanModal;
