import I18 from "../../_components/atoms/i18";
import { NL2BR } from "../../_components/nl2br.component";
import { history } from "../../_config";
import { useAppService } from "../../hooks/use-app-service";
import { getGroupPageRouteFromSlug } from "../group";
import JoinButton from "../group/group-join-button.component";
interface ExploreGroupComponentProps {
  user: any;
  groups: any[];
  setGroup: (group: any) => void;
}
function ExploreGroupComponent({
  user,
  groups = [],
  setGroup = (e) => {},
}: ExploreGroupComponentProps) {
  //get total members counts
  // const [members, setMembers] = useState<any>({});

  // useEffect(() => {
  //   async function fetchMembers(groupId: string) {
  //     try {
  //       const members = await GroupService.groupMembers(
  //         abortController,
  //         groupId,
  //         undefined
  //       );
  //       setMembers((prevMembers: any) => ({
  //         ...prevMembers,
  //         [groupId]: members,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   // Call fetchMembers for each group in the groups array
  //   groups.forEach((group) => {
  //     fetchMembers(group.id);
  //   });
  // }, [groups, user]);

  if (groups.length === 0) {
    return (
      <div
        className="no-post p-2 my-10 text-center"
        style={{ minWidth: "476px" }}>
        <div className="font-bold my-3">
          <I18>Looks like this channel is empty.</I18>
        </div>
        <div>
          <I18>
            Create a group if you are admin, or wait for a group to be added.
          </I18>
        </div>
      </div>
    );
  }
  return (
    <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 my-4">
      {groups.map((group) => (
        <GroupDetails
          key={group.id}
          group={group}
          setGroup={setGroup}
          user={user}
        />
      ))}
    </div>
  );
}
interface GroupDetailsProps {
  user: any;
  group: any;
  setGroup?: (e: any) => void;
}
function GroupDetails({ group, setGroup, user }: GroupDetailsProps) {
  const { analyticsService } = useAppService();

  return (
    <div
      key={group.id}
      onClick={(e) => {
        analyticsService.track("group-clicked-in-explore-groups", {
          joinStatus: group.joinStatus,
        });
        history.push(getGroupPageRouteFromSlug(group));
      }}
      className="GroupCard flex justify-center rounded items-center cursor-pointer flex-col">
      <div className="theme-bg-surface rounded w-full flex-grow flex flex-col">
        {/* background image */}
        {group.banner && !group.color ? (
          <div className="GroupBanner overflow-hidden relative rounded-t absolute top-0 left-0 w-full rounded-t">
            <img
              src={group.banner}
              alt="Group Banner"
              className="absolute w-full top-0 left-0 object-cover rounded-t"
            />
          </div>
        ) : (
          <div
            className="GroupBanner color theme-bg-primary  object-cover rounded-t"
            style={{
              ...(group.color ? { backgroundColor: group.color } : {}),
            }}></div>
        )}
        {/* group name and description */}
        <div className="font-semibold w-full px-3 pb-1 pt-3">{group.name}</div>
        <div className="font-semibold w-full text-xs theme-text-subtitle-1 mt-1 px-3 flex-grow overflow-hidden pb-10 h-7">
          <NL2BR
            text={
              group?.description?.length > 60
                ? group?.description?.substring(0, 57) + "..."
                : group?.description
            }
          />
        </div>
        <div className="px-3 py-1 w-full flex justify-between items-center">
          <div className="font-semibold w-full text-xs theme-text-subtitle-1 mt-1 ">
            👥 &nbsp; {group?.userCount}
          </div>
          <JoinButton
            group={group}
            setGroup={setGroup}
            user={user}
            openGroupOnJoined
          />
        </div>
      </div>
    </div>
  );
}

const ExploreGroup = ExploreGroupComponent;
export default ExploreGroup;
