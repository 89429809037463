import cx from "classnames";
import { format, formatDistanceToNow, isAfter, isBefore } from "date-fns";
import React, { Suspense, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Remarkable } from "remarkable";
import { ToastTypes, withToast } from "../contexts/toastr.context";
import useLang from "../hooks/use-lang.hook";
import { Button } from "../_components";
import { ActionEventModal } from "../_components/action-event-modal.component";
import { ActionModal } from "../_components/action-modal.component";
import I18 from "../_components/atoms/i18";
import { Avatar } from "../_components/avatar.component";
import SidebarEventDetailsCard from "../_components/event/sidebar-event-details-card.component";
import IconMenu2 from "../_components/icon-menu-2.component";
import { Loader } from "../_components/loader.component";
import { history } from "../_config";
import { EventService } from "../_service/event.service";
import { createMeetingLinkWithToken } from "./meeting.page";
import { useAppService } from "../hooks/use-app-service";
import { EventCreateModel } from "../_components/event/create-event-modal-component";

export const EVENTS_DETAIL_PAGE_ROUTE = "/event/:id";
export const EVENTS_DETAIL_SLUG_PAGE_ROUTE = "/e/:slug";
export const createEventDetailPageRoute = (eventId) => "/event/" + eventId;
export function createEventDetailSlugPageRoute(event) {
  if (
    event.meta != undefined &&
    event.meta != null &&
    event.meta.slug != null
  ) {
    return "/e/" + event.meta.slug;
  } else {
    return createEventDetailPageRoute(event.id);
  }
}
export function createPostDetailPageUrl(event) {
  return window.location.origin + createEventDetailSlugPageRoute(event);
}

function EventDetailPageComponent({ user, community, addToast }) {
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteEvent, setIsDeleteEvent] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [event, setEvent] = useState();
  const { id, slug } = useParams();
  const [activeModel, setActiveModel] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isShowAttendee, setIsShowAttendee] = useState(false);
  const [isShowInvite, setIsShowInvite] = useState(false);
  const [notifyUser, setNotifyUser] = useState(false);
  const [isNotifyModalVisible, setIsNotifyModalVisible] = useState(false);

  const { analyticsService } = useAppService();

  const lang = useLang();

  React.useEffect(() => {
    setIsLoading(true);
    getEventDetails();
  }, [id, slug]);

  React.useEffect(() => {
    if (notifyUser) {
      onSubmitDelete();
    }
  }, [notifyUser]);

  const getEventDetails = () => {
    if (id) {
      EventService.getEventDetail(user, community.id, id)
        .then((response) => {
          setEvent(response.event);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast("Events could not be fetched!", "", ToastTypes.danger);
          setIsLoading(false);
        });
    } else if (slug) {
      EventService.getEventDetailBySlug(user, community.id, slug)
        .then((response) => {
          setEvent(response.event);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast("Events could not be fetched!", "", ToastTypes.danger);
          setIsLoading(false);
        });
    }
  };

  const onSubmitDelete = () => {
    setIsDeleteEvent(true);
    setIsDeleteModalVisible(true);
    EventService.deleteEvent(user, community.id, event.id, notifyUser)
      .then((response) => {
        // delete event
        analyticsService.track("event-delete")
        addToast("Event deleted!");
        history.push("/events");
      })
      .catch((err) => {
        console.log(err);
        setIsDeleteModalVisible(true);
        addToast("Event could not be deleted!", "", "danger");
      })
      .finally(() => {
        setIsDeleteEvent(false);
        setIsDeleteModalVisible(false);
      });
  };

  const onAttendeeClick = () => {
    setIsShowAttendee(true);
    setActiveModel(true);
  };

  const onInviteClick = () => {
    setIsShowInvite(true);
    setActiveModel(true);
  };

  // should notifyUser
  const onSubmitNotify = () => {
    setNotifyUser(true);
    setIsNotifyModalVisible(false);
  };

  // dont notifyUser
  const onCancelNotify = () => {
    setIsNotifyModalVisible(false);
    onSubmitDelete();
  };

  if (event === undefined || event === null) {
    return (
      <div className="Card theme-bg-surface h-96 flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  const isMeetingScheduled = event.startTime;
  const isScheduleMeetingStarted = isMeetingScheduled
    ? isBefore(new Date(event.startTime), Date.now())
    : false;
  const isScheduleMeetingEnded = isMeetingScheduled
    ? isAfter(Date.now(), new Date(event.endTime))
    : false;

  const joinScheduledMeeting = (e) => {
    e.preventDefault();
    if (user) {
      if (!isScheduleMeetingEnded) {
        window.open(
          createMeetingLinkWithToken(event.liveMeeting.meetingId, user.token)
        );
        analyticsService.track("join-event-button-clicked", {
          eventURL: event.liveMeeting.meetingId
        })
      } else if (isScheduleMeetingEnded) {
        addToast("Event over", "", ToastTypes.info);
      }
    }
  };
  const joinScheduledPersonalMeeting = (e) => {
    e.preventDefault();
    analyticsService.track("join-event-button-clicked", {
      eventURL: event.webURL
    })
    window.open(event.webURL);
    // open the meeting page with user token
    // if (user) {
    //   // check if scheduled time is passed
    //   if (isScheduleMeetingStarted) {
    //     window.open(event.webURL);
    //   } else {
    //     addToast(
    //       "You can join the event after scheduled time",
    //       "",
    //       ToastTypes.info
    //     );
    //   }
    // }
  };
  return (
    <>
      <div className="PostCard ">
        {event ? (
          <div className="mb-2 px-4 sm:px-0">
            <div
              className="cursor-pointer font-semibold"
              onClick={() => history.push("/events")}>
              {" "}
              ᐸ &nbsp;<I18>Events</I18>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="flex flex-col space-y-4 rounded">
          {/* EVENT */}
          <div className=" w-full theme-bg-surface border theme-border-default rounded ml-3 sm:ml-0">
            {event.banner && (
              <div className="flex relative place-content-center theme-bg-disable eventBannerImage rounded">
                <div className="absolute top-0 left-0 w-full h-full rounded-t">
                  <img
                    src={event.banner}
                    className="absolute w-full h-full top-0 left-0 object-cover rounded-t"
                    alt=""
                    // style={{maxWidth:"100%", maxHeight:"56.49%", height:'auto',width:'auto'}}
                  />
                </div>
              </div>
            )}

            <div className="p-4 ">
              <UserTile onInviteClick={onInviteClick} />
              <EventInfo />
            </div>
          </div>
        </div>
          <EventCreateModel
            active={activeModel}
            user={user}
            community={community}
            eventToUpdate={event}
            onUpdateEvent={(event) => {
              setEvent(event);
              history.push(createEventDetailSlugPageRoute(event));
            }}
            onNewEventAdded={(event) => {}}
            isShowAttendee={isShowAttendee}
            setIsShowAttendee={setIsShowAttendee}
            isShowInvite={isShowInvite}
            setIsShowInvite={setIsShowInvite}
            openModel={(val) => {
              setActiveModel(val);
            }}
          />
        <ActionModal
          active={isDeleteModalVisible}
          setActive={setIsDeleteModalVisible}
          onSubmit={() => {
            if (isAfter(Date.now(), new Date(event.endTime))) {
              onCancelNotify();
            } else {
              setIsDeleteModalVisible(false);
              setIsNotifyModalVisible(true);
            }
          }}
          header={"Delete Event"}
          title={"Are you sure you want to delete the event?"}
          isLoading={isDeleteEvent}
          btnColor="red"
        />
        <ActionEventModal
          active={isNotifyModalVisible}
          setActive={setIsNotifyModalVisible}
          onSubmit={onSubmitNotify}
          onCancelSubmit={onCancelNotify}
          labelCancel="No, its okay"
          labelSubmit="Yes, Notify them"
          title={"Do you wish to notify that event is cancelled?"}
          subTitle={`${
            event.participantCount > 0
              ? `${event.participantCount} ${lang.trans(
                  "users wants to attend this event"
                )}`
              : ""
          } `}
          isLoading={isLoading}
          large
          btnColor="theme-bg-disabled"
        />
      </div>
      <div className="mt-0 md:mt-7">
        <SidebarEventDetailsCard
          event={event}
          addToast={addToast}
          user={user}
          joinScheduledMeeting={joinScheduledMeeting}
          joinScheduledPersonalMeeting={joinScheduledPersonalMeeting}
          onAttendeeClick={onAttendeeClick}
          getEventDetails={getEventDetails}
        />
      </div>
    </>
  );

  function EventInfo() {
    return (
      <div>
        <p className="font-semibold my-2 text-lg">{event.title}</p>
        {event.description && <EventDescription event={event} user={user} />}

        {event.meetingLink && (
          <p className="text-xs my-1 theme-text-subtitle-1">
            {event.meetingLink}
          </p>
        )}
        <EventHostSection
          isMeetingScheduled={isMeetingScheduled}
          joinScheduledMeeting={joinScheduledMeeting}
          isScheduleMeetingEnded={isScheduleMeetingEnded}
          isScheduleMeetingStarted={isScheduleMeetingStarted}
          joinScheduledPersonalMeeting={joinScheduledPersonalMeeting}
          event={event}
          user={user}
          addToast={addToast}
        />
      </div>
    );
  }

  function UserTile({ onInviteClick = () => {} }) {
    if (event === undefined || event === null || event.createdBy === null) {
      return <></>;
    }
    const createdBy = event.createdBy;
    return (
      <div className="flex space-x-2 justify-between">
        <div className="flex space-x-2">
          <img
            className="rounded-full h-8 w-8"
            src={createdBy.picture}
            alt="Created by"
          />
          <div className="flex flex-col">
            <p className="font-semibold">{createdBy.name}</p>
            <p className="text-xs theme-text-subtitle-2">
              {formatDistanceToNow(new Date(event.createdAt))}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          {user &&
            event.myCommunityRole === "admin" &&
            isAfter(new Date(event.startTime), Date.now()) && (
              <>
                <div
                  onClick={() => onInviteClick()}
                  className="mr-2 cursor-pointer text-xs font-semibold">
                  <I18>Share</I18>
                </div>
                <IconMenu2
                  icon="menu-dots"
                  actions={[
                    {
                      icon: "edit",
                      label: "Edit",
                      onClick: (e) => {
                        setActiveModel(true);
                      },
                    },
                    {
                      icon: "trash",
                      label: "Delete",
                      labelClass: "theme-text-danger",
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                      actionType: "alert",
                    },
                  ]}
                />
              </>
            )}
          {user &&
            event.myCommunityRole === "admin" &&
            isAfter(Date.now(), new Date(event.endTime)) && (
              <>
                <IconMenu2
                  icon="menu-dots"
                  actions={[
                    {
                      icon: "trash",
                      label: "Delete",
                      labelClass: "theme-text-danger",
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                      actionType: "alert",
                    },
                  ]}
                />
              </>
            )}
        </div>
      </div>
    );
  }
}

function getVideoEmbedFromDescription(description = "") {
  // check for youtube video // for link
  let newDescription = description.replace(
    /(<a href=")(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?:watch)?(?:\?v=)?([^&<"\>\(\)\[\] \n]+)([^"]+)(">)([^<]+)(<\/a>)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$2" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );
  // for text link
  newDescription = description.replace(
    /(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?:watch)?(?:\?v=)?([^&<"\>\(\)\[\] \n]+)([^" \n]+)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$1" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );
  // check for vimeo video
  newDescription = newDescription.replace(
    /(?:[^"'https:\/\/])(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:player\.)?(?:vimeo.com)\/(?:video\/)?([^<"\>\(\)\[\] \n]+)/g,
    '<p><iframe title="Vimeo Embed" src="https://player.vimeo.com/video/$1" width="100%" height="360" className="my-2" frameBorder="0" allow="fullscreen" allowFullScreen></iframe></p>'
  );

  return newDescription;
}
/**
 * Event description
 * @param {*} param0
 * @returns
 */
function EventDescription({ event, user }) {
  const [fullView, setFullView] = useState(false);

  let { description } = event;

  if (!description) return <></>;

  const md = new Remarkable();

  const renderedDescription = md.render(description);

  let descriptionWithMentions = renderedDescription;
  try {
    descriptionWithMentions = renderedDescription.replace(
      /\B\@([\w\-]+)/gim,
      (match) => {
        return `<span class="theme-text-primary cursor-pointer theme-theme-bg-primary-light">${match}</span>`;
      }
    );
  } catch (error) {}

  const descriptionWithEmbeds = getVideoEmbedFromDescription(
    descriptionWithMentions
  );

  return (
    <div className={cx("my-4 break-words")}>
      <div
        className="NL2BR headings"
        dangerouslySetInnerHTML={{ __html: descriptionWithEmbeds }}></div>
      {/* <PostYoutubeEmbedFromDescription description={post.description} /> */}
      {/* <PostVimeoEmbedFromDescription description={post.description} /> */}
    </div>
  );
}
/**
 * Event Host
 * @param {*} param0
 * @returns
 */
function EventHostSection({
  event,
  user,
  addToast,
  joinScheduledPersonalMeeting,
  isMeetingScheduled,
  joinScheduledMeeting,
  isScheduleMeetingEnded,
}) {
  return (
    <div className="theme-bg-default w-full rounded px-4 py-4">
      <div className="font-semibold mb-2">Host</div>
      <Avatar user={event.host} />
      {event.isOnline || event.location ? (
        <div className="mt-3 mb-3 border-b theme-border-default " />
      ) : null}
      <div>
        {user &&
        event.myRSVPStatus !== "going" &&
        (user.id !== event.host.id || event.myCommunityRole !== "admin") &&
        event.isOnline ? (
          <div className="flex py-2 items-center">
            <i
              className={
                "fi fi-rr-video-camera text-base pr-4 theme-text-primary"
              }></i>
            <div className="theme-text-primary pb-1">
              Online Event (Meeting link will be visible once registered for the
              event)
            </div>
          </div>
        ) : null}
        {user &&
        (event.myRSVPStatus === "going" ||
          user.id === event.host.id ||
          event.myCommunityRole === "admin") ? (
          <>
            {event.liveMeeting !== null ? (
              <Button
                onClick={joinScheduledMeeting}
                className="mr-2 p-4"
                label="Join Event"
                large
              />
            ) : null}
            {event.webURL && event.liveMeeting === null ? (
              <Button
                onClick={joinScheduledPersonalMeeting}
                className="mr-2 p-4"
                label="Join Event"
                large
              />
            ) : null}
          </>
        ) : null}

        {event.location ? (
          <div className="flex py-2 items-center">
            <i
              className={
                "fi fi-rr-marker text-base pr-4 theme-text-primary"
              }></i>
            <div className="pb-1">{event.location}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const EventDetailPage = withToast(
  connect((s) => ({ user: s.auth, community: s.community }))(
    EventDetailPageComponent
  )
);

// Return event start and end time
// if start time is same as end time then return only start time
export function formatEventTime(start, end) {
  const startTime = new Date(start);
  const endTime = new Date(end);
  if (startTime.getTime() === endTime.getTime()) {
    return format(startTime, "dd MMMM, yyyy, p");
  } else if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  ) {
    /// When Event is on same date
    return format(startTime, "dd MMMM, yyyy, p");
  } else if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth()
  ) {
    /// When Event is in same month
    return `${format(startTime, "dd MMM, p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  } else if (startTime.getFullYear() === endTime.getFullYear()) {
    /// When Event is in same year
    return `${format(startTime, "dd MMM,  p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  } else {
    /// When Event is in different year
    return `${format(startTime, "dd MMM, yyyy, p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  }
}

export default EventDetailPage;
