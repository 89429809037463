import cx from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withToast } from "../../contexts/toastr.context";
import ellipsisIcon from "../../_assets/vectors/ellipsis-icon.svg";
import { history } from "../../_config";
import { getGroupPageRouteFromSlug } from "../../_pages";
import { NotificationLevel } from "../../_pages/community-settings/notification-centre.page";
import JoinButton from "../../_pages/group/group-join-button.component";
import { GroupService } from "../../_service";
import AnalyticsService, { EventType } from "../../_service/analytics-service";
import { RealtimeSectionPinnedMessageService } from "../../_service/realtime-section-pinned-message.service";
import { store } from "../../_store";
import { updateNotificationPreferenceForSection } from "../../_store/_actions/notification-centre.actions";
import { ActionModal } from "../action-modal.component";
import { Button } from "../button.component";
import EmbedLinkModal from "../embed-link-modal.component";
import { CreateGroupModal } from "../group/create-group-modal.component";
import { CreateSectionModal } from "../group/create-section-modal.component";
import ClosedSectionRequestButton from "../group/section-detail/component/closed-section-request-button.component";
import IconMenu2, { ActionType } from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import { ShareTextModal } from "../share-text-modal.component";
import Label from "../typography/label.typography";
import { PinnedMessageComponent } from "./realtime-pinned-section.component";

function RealtimeInfoComponent({
  group,
  setGroup,
  user,
  activeTabModel,
  activeTab,
  showShareModal,
  setShowShareModal,
  notificationCentre,
  community,
  socket,
  addToast = (e) => {},
}) {
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);
  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [isEditingTab, setIsEditingTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [pinnedSectionMessages, setPinnedSectionMessages] = useState([]);

  const onSubmitDelete = () => {
    setIsLoading(true);
    GroupService.deleteGroupTab(user, group.id, activeTabModel.id)
      .then(({ group }) => {
        // update the group
        setIsLoading(false);
        setGroup(group);
        setIsDeleteModalVisible(false);
      })
      .catch((error) => {
        console.log({ error });
        alert("Something went wrong!");
        setIsLoading(false);
        setIsDeleteModalVisible(false);
      });
  };

  const onSubmitLeave = () => {
    setIsLoading(true);
    GroupService.leaveGroup(user, group.id)
      .then((response) => {
        // update group
        setGroup(response.group);
        // set loading to false
        setIsLoading(false);
        setIsLeaveModalVisible(false);
        AnalyticsService.logEvent(EventType.Group.name, EventType.Group.leave);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsLeaveModalVisible(false);
      });
  };

  // Stores the active tab notification preference
  const [notificationLevel, setNotificationLevel] = useState(
    NotificationLevel.NONE
  );

  let isVisibleToNormalUser =
    !(
      group.joinStatus === "joined" &&
      activeTabModel.isClosed &&
      !activeTabModel.isJoined
    ) || ["admin", "moderator"].includes(group.myRole);

  //get pinned section messages
  useEffect(() => {
    const getPinnedMessages = async () => {
      try {
        const result =
          await RealtimeSectionPinnedMessageService.getPinnedSectionMessages(
            group?.id,
            activeTabModel?.id
          );
        setPinnedSectionMessages(result);
      } catch (error) {
        console.error("Error fetching pinned message:", error);
      }
    };

    getPinnedMessages();
  }, [group?.id, activeTabModel?.id]);

  // Realtime pinned section messages socket implementation
  useEffect(() => {
    //pins the message in realtime
    const pinMessage = (data) => {
      setPinnedSectionMessages((prevState) => {
        const updatedPinnedMessages = [
          data.message,
          ...prevState.pinnedMessages,
        ];
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    //unpins the message in realtime
    const unPinMessage = (data) => {
      setPinnedSectionMessages((prevState) => {
        const updatedPinnedMessages = prevState.pinnedMessages.filter(
          (message) => message.id !== data.message.id
        );
        return { ...prevState, pinnedMessages: updatedPinnedMessages };
      });
    };

    socket.on(`section:${activeTabModel.id}:message-pinned`, pinMessage);
    socket.on(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);

    // Clean up event listeners when the component unmounts
    return () => {
      socket.off(`section:${activeTabModel.id}:message-pinned`, pinMessage);
      socket.off(`section:${activeTabModel.id}:message-unpinned`, unPinMessage);
    };
  }, [socket, activeTabModel.id]);

  //accessing Pinned messages from state
  const NewPinnedMessages = pinnedSectionMessages?.pinnedMessages;

  // Initialize the notification preference for selected tab
  useEffect(() => {
    if (notificationCentre.preferences && activeTabModel) {
      const preferences = notificationCentre.preferences;
      if (preferences.tabs && preferences.tabs.length > 0) {
        // Check if the user has a preference for this section
        const tab = preferences.tabs.find(
          (tab) => tab.tabId === activeTabModel.id
        );
        if (tab) {
          setNotificationLevel(tab.notificationLevel);
        }
        // If not, set the default preference to NotificationLevel.ALL
        else {
          setNotificationLevel(NotificationLevel.ALL);
        }
      } else {
        setNotificationLevel(NotificationLevel.ALL);
      }
    }
  }, [notificationCentre, activeTab]);

  return (
    <>
      {/*  BREADCRUMB */}
      <div
        className="my-2 block font-semibold cursor-pointer"
        onClick={() => history.push(getGroupPageRouteFromSlug(group))}>
        <Label>ᐸ &nbsp; {group.name}</Label>
      </div>

      {/* group info */}

      {/* group title */}
      <div className="RealtimeSectionInfo flex justify-between items-center shadow theme-bg-surface rounded-t py-3 border-b theme-border-default">
        <Label
          variant="t1"
          size="body1"
          className="px-4 overflow-ellipsis flex-grow line-clamp-1">
          {activeTabModel && activeTabModel.emoji + " " + activeTabModel.name}
        </Label>

        <div className="flex items-center flex-none">
          {/* join / invite button, on group level only */}
          <div className="">
            {group.joinStatus === "joined" &&
            ["admin", "moderator"].includes(group.myRole) ? (
              activeTabModel ? (
                <></>
              ) : (
                <button
                  onClick={(e) => {
                    setShowShareModal(true);
                  }}
                  className="border theme-border-primary theme-text-primary font-semibold rounded px-5 py-2 focus:outline-none">
                  Invite Members
                </button>
              )
            ) : group.joinStatus === "joined" ? (
              <div className="mr-2">
                {activeTabModel.isClosed &&
                  !activeTabModel.isJoined &&
                  (activeTabModel.status === "requested" ? (
                    <Button label="Requested" disabled />
                  ) : (
                    <ClosedSectionRequestButton
                      activeTabModel={activeTabModel}
                      user={user}
                      group={group}
                      updateActiveGroup={setGroup}
                      setGroup={setGroup}
                      addToast={addToast}
                    />
                  ))}
              </div>
            ) : (
              // Display join Group button if group is open and active section is not private .
              !activeTabModel.isPrivate && (
                <div className="pr-2">
                  {
                    // Hide Join group button if group is closed and section is also closed
                    group.groupType !== "closed-free" &&
                      !activeTabModel.isClosed && (
                        <JoinButton
                          group={group}
                          setGroup={setGroup}
                          user={user}
                        />
                      )
                  }
                </div>
              )
            )}
          </div>
          {
            // show menu only if joined
            isVisibleToNormalUser && group.joinStatus === "joined" && (
              <div className="flex gap-1 mr-1">
                <PinnedMessageComponent
                  community={community}
                  pinnedMessages={NewPinnedMessages}
                />
                <IconMenu2
                  icon="bell"
                  solid={notificationLevel !== NotificationLevel.NONE}
                  className={cx("w-6 h-6", {
                    "theme-text-primary w-":
                      notificationLevel !== NotificationLevel.NONE,
                  })}
                  actions={[
                    // For All notifications
                    {
                      label: "All posts",
                      icon: "view-grid",
                      onClick: () => {
                        let tab = {
                          tabId: activeTabModel.id,
                          groupId: group.id,
                          notificationLevel: NotificationLevel.ALL,
                        };
                        store.dispatch(
                          updateNotificationPreferenceForSection(tab, addToast)
                        );
                      },
                      actionType:
                        notificationLevel === NotificationLevel.ALL
                          ? ActionType.primary
                          : ActionType.default,
                    },
                    // For admin posts only
                    {
                      label: "Admin posts only",
                      icon: "view-grid",
                      onClick: () => {
                        let tab = {
                          tabId: activeTabModel.id,
                          groupId: group.id,
                          notificationLevel: NotificationLevel.ADMIN,
                        };
                        store.dispatch(
                          updateNotificationPreferenceForSection(tab, addToast)
                        );
                      },
                      actionType:
                        notificationLevel === NotificationLevel.ADMIN
                          ? ActionType.primary
                          : ActionType.default,
                    },
                    // For no notifications
                    {
                      label: "None",
                      icon: "view-grid",
                      onClick: () => {
                        let tab = {
                          tabId: activeTabModel.id,
                          groupId: group.id,
                          notificationLevel: NotificationLevel.NONE,
                        };
                        store.dispatch(
                          updateNotificationPreferenceForSection(tab, addToast)
                        );
                      },
                      actionType:
                        notificationLevel === NotificationLevel.NONE
                          ? ActionType.primary
                          : ActionType.default,
                    },
                  ]}
                />
                <IconMenu2
                  // icon="img"
                  img={ellipsisIcon}
                  className="cursor-pointer w-6 h-6"
                  iconButtonSmall
                  actions={[
                    {
                      // show share option
                      label: "Share",
                      icon: "share",
                      onClick: () => {
                        setShowShareModal(true);
                      },
                    },
                    {
                      // show embed link if active modal
                      label: activeTabModel ? "Embed Channel" : null,
                      icon: "globe",
                      onClick: () => {
                        setEmbedModalVisible(true);
                      },
                    }, // now other options for moderator or admin
                    {
                      // for tab menu
                      label:
                        activeTabModel && "admin" === group.myRole
                          ? "Edit Channel"
                          : null,
                      icon: "edit",
                      onClick: () => {
                        setIsEditingTab(true);
                      },
                    },
                    {
                      // for section delete
                      label:
                        activeTabModel && "admin" === group.myRole
                          ? "Delete Channel"
                          : null,
                      icon: "trash",
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                      actionType: "alert",
                    }, // now show options that are only available to admin when no section is selected

                    {
                      // leave button for normal user
                      label: group.myRole !== "admin" ? "Leave Group" : null,
                      labelClass: "theme-text-danger",
                      onClick: (e) => {
                        setIsLeaveModalVisible(true);
                      },
                    },
                  ]}
                />
              </div>
            )
          }
          {/* edit tab model */}
          <CreateSectionModal
            user={user}
            activeGroup={group}
            activeTab={activeTabModel}
            active={isEditingTab}
            setActive={setIsEditingTab}
            updateActiveGroup={setGroup}
          />
          <ShareTextModal
            heading="Invite Members"
            text={window.location.href}
            active={showShareModal}
            setActive={setShowShareModal}
            onClose={(e) => {
              setShowShareModal(false);
            }}
          />
          {isLoading ? <Loader /> : <></>}
        </div>
      </div>

      {/* group edit modal */}
      <CreateGroupModal
        group={group}
        updateGroup={(group) => {
          addToast("Group updated!", "", "success");
          setGroup(group);
        }}
        active={isCreateGroupModalVisible}
        setActive={setIsCreateGroupModalVisible}
      />
      {/* embed modal */}
      {activeTabModel && (
        <EmbedLinkModal
          link={
            process.env.REACT_APP_EMBED_URL +
            (window.location.port ? ":" + window.location.port : "") +
            "/group/" +
            group.id +
            "/feed/" +
            activeTabModel.id
          }
          active={isEmbedModalVisible}
          setActive={setEmbedModalVisible}
        />
      )}
      {/* delete tab model */}
      <ActionModal
        active={
          isLeaveModalVisible ? isLeaveModalVisible : isDeleteModalVisible
        }
        setActive={
          isLeaveModalVisible ? setIsLeaveModalVisible : setIsDeleteModalVisible
        }
        onSubmit={isLeaveModalVisible ? onSubmitLeave : onSubmitDelete}
        header={`${isLeaveModalVisible ? "Leave" : "Delete"} Channel`}
        btnColor="red"
        title={`Are you sure you want to ${
          isLeaveModalVisible ? "leave" : "delete"
        } the Channel?`}
        // isLoading={isLoading}
      />
    </>
  );
}

export const RealtimeSectionInfo = withToast(
  connect((s) => ({
    notificationCentre: s.notificationCentre,
    community: s.community,
    socket: s.socket,
  }))(RealtimeInfoComponent)
);
