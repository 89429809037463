// import { DyteMeeting, Meeting } from "dyte-client";
import { useState } from "react";
import { Loader } from "../../_components";
import { CreateSectionModal } from "../../_components/group/create-section-modal.component";
import IconButton2 from "../../_components/icon-button-2-component";
import IconMenu2 from "../../_components/icon-menu-2.component";
import { history } from "../../_config";
import { getSectionPageRouteFromSlug } from "../group";

function GroupSectionComponent({
  onSubmit,
  group,
  user,
  tab,
  updateGroup = (e) => {},
  addToast = (e) => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onSubmitDelete = () => {
    onSubmit(tab.id);
  };
  return (
    <>
      <div
        onClick={() =>
          // history.push(getGroupPageRoute(group.id) + "?tab=" + tab.id)
          history.push(getSectionPageRouteFromSlug(group, tab))
        }
        className="GroupSection tab theme-bg-surface rounded my-2 px-4 py-4 flex items-center justify-between cursor-pointer ">
        <div className="flex-grow font-semibold flex items-center">
          {tab.emoji ? tab.emoji : "#"}
          <div className="px-1 pl-1">{tab.name}</div>
        </div>
        <div className="flex items-center">
          <IconButton2
            hoverable={false}
            icon="angle-right"
            onClick={() => {}}
          />
          {/* show menu if group by me */}
          {["admin", "moderator"].includes(group.myRole) ? (
            <IconMenu2
              className={isLoading ? "hidden" : "theme-text-primary"}
              actions={[
                {
                  icon: "edit",
                  label: "Edit",
                  onClick: (e) => {
                    // prevent it from opening the section
                    e.preventDefault();
                    setIsEditing(true);
                  },
                },
                {
                  icon: "trash",
                  label: "Delete",
                  labelClass: "theme-text-danger",
                  onClick: (e) => {
                    // prevent it from opening the section
                    e.preventDefault();
                    // delete the section
                    onSubmitDelete();
                  },
                  actionType: "alert",
                },
              ]}
            />
          ) : (
            <></>
          )}
          {/* loader */}
          {isLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* edit tab model */}
      <CreateSectionModal
        user={user}
        activeGroup={group}
        activeTab={tab}
        active={isEditing}
        setActive={setIsEditing}
        updateActiveGroup={updateGroup}
      />
    </>
  );
}

const GroupSection = GroupSectionComponent;
export default GroupSection;
