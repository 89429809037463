import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux.hook";
import Confetti from "../../_assets/lottie/confetti.json";
import { LeaderboardPage } from "../../_pages/leaderboard.page";
import RightSideModal from "../right-side-modal.component";
import Label from "../typography/label.typography";
import UIcon from "../uicon-component";
import { CommunityService } from "../../_service";
const LottiePlayer = lazy(() => import("../molecule/lottie-player"));

interface KarmaPoints {
  grandTotal: number;
  'post-create': number;
  'post-like': number;
  'post-liked': number;
  'post-comment': number;
  'comment-reply': number;
  'post-commented': number;
  'comment-replied': number;
  'admin-assigned': number;
}

function KarmaPointsHeaderButtonComponent() {
  const { community, user, communityKarma } = useAppSelector((state) => {
    return {
      community: state.community,
      user: state.auth,
      communityKarma: state.communityKarmaPoints,
    };
  });

  const [active, setActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [points, setPoints] = useState(0);
  const [pointsBreakdown, setPointsBreakdown] = useState<KarmaPoints>({
    grandTotal: 0,
    "post-create": 0,
    "post-like": 0,
    "post-liked": 0,
    "post-comment": 0,
    "comment-reply": 0,
    "post-commented": 0,
    "comment-replied": 0,
    "admin-assigned": 0,
  });
  const [displayConfetti, setDisplayConfetti] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const getMyKarmaPoints = async (communityId: any) => {
    const currentUserKarmaPoints = await CommunityService.getMyKarmaPoints(communityId);
    if (currentUserKarmaPoints.myKarmaPoints) {
      setPoints((currentUserKarmaPoints.myKarmaPoints.karmaPoints?.grandTotal || 0) + (community?.configurables?.COMMUNITY_BASE_POINT_KEY || 0));
      setPointsBreakdown(currentUserKarmaPoints.myKarmaPoints.karmaPoints);
    }
  };

  // hide when click outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowDetails(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
      setPoints(points + communityKarma?.addedPoints || 0);
      setPointsBreakdown(prevState => ({
        ...prevState,
        grandTotal: (prevState.grandTotal || 0) + communityKarma?.addedPoints,
        [communityKarma?.activityType]: (prevState[communityKarma?.activityType as keyof KarmaPoints] || 0) + (communityKarma?.addedPoints || 0)
      }));  

      // TODO: add confetti

      // setDisplayConfetti(true);
      // setTimeout(() => {
      //   setDisplayConfetti(false);
      // }, 3000);
  }, [communityKarma]);

  useEffect(() => {
    if (community) {
      getMyKarmaPoints(community.id) 
    }
  }, [community]);

  if (
    !community ||
    !user ||
    community?.configuration?.hideHeaderLeaderboardPill
  ) {
    return <></>;
  }
  return (
    <div className="relative">
      {displayConfetti && (
        <div className="absolute inset-0 -top-4">
          <Suspense fallback={<></>}>
           <LottiePlayer autoplay={true} src={Confetti} className="h-14 w-14" />
          </Suspense>
        </div>
      )}
      {/* Count button */}
      <KarmaButton
        onClick={() => {
          setShowDetails(!showDetails);
        }}
        className="sm:flex KarmaButton   p-1 pl-3.5 pr-3.5 rounded-full cursor-pointer">
        <span className="items-center flex relative">
          <span role="img" aria-label="emoji">
            🔥
          </span>
          <span className="pl-2 text-black">
            {points || "0"}
          </span>
          <div ref={modalRef}>
            {showDetails ? (
              <DroppedDetails
                community={community}
                pointsBreakdown={pointsBreakdown}
                totalPoints={points}
                close={() => {
                  setShowDetails(false);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </span>
        <RightSideModal width={1000} active={active} setActive={setActive}>
          <LeaderboardPage fullHeight={true} />
        </RightSideModal>
      </KarmaButton>
    </div>
  );
}

const KarmaButton = styled.div`
  .dropped-details {
    display: block;
    top: 50px;
    width: 240px;
    right: 0;
    &:before {
      display: flex;
      display: block;
      content: "";
      position: absolute;
      border: 5px solid transparent;
      border-left: 5px solid white;
      border-top: 5px solid white;
      transform: rotate(45deg);
      top: -5px;
      right: 10px;
    }
  }
  &:hover {
    .dropped-details {
      display: block;
    }
  }
`;

function DroppedDetails({ community, pointsBreakdown, totalPoints, close }: any) {
  return (
    <div className="dropped-details absolute theme-bg-surface px-4 py-5 rounded shadow-lg mt-1">
      <div className="flex justify-between items-center">
        {community.myRole === "admin" ? (
          <Link to="/settings/leaderboard">Go to leaderboard</Link>
        ) : (
          <></>
        )}
        <div
          className="text-red-500 p-1 rounded-full theme-bg-default flex w-8 h-8 justify-center items-center cursor-pointer hover:shadow"
          onClick={() => {
            close();
          }}>
          <UIcon icon="cross" />
        </div>
      </div>
      {/* breakup */}
      <table className="mt-2">
        <thead>
          <tr>
            <td className="w-48 px-2">
              <Label variant="t1">Total karma points</Label> 🔥
            </td>
            <td className="w-10 px-2 font-bold text-lg">
              {totalPoints || 0}
            </td>
          </tr>
        </thead>
        <tbody>
          {pointsBreakdown && totalPoints > 0 ? (
            <>
              <tr>
                <td className="px-2 py-1">Comments</td>
                <td className="px-2 py-1">
                  {pointsBreakdown["post-comment"] || 0}
                </td>
              </tr>
              <tr>
                <td className="px-2 py-1">Upvotes</td>
                <td className="px-2 py-1">
                  {pointsBreakdown["post-like"] || 0}
                </td>
              </tr>
              <tr>
                <td className="px-2 py-1">Replies</td>
                <td className="px-2 py-1">
                  {pointsBreakdown["comment-reply"] || 0}
                </td>
              </tr>
              <tr>
                <td className="px-2 py-1">Post</td>
                <td className="px-2 py-1">
                  {pointsBreakdown["post-create"] || 0}
                </td>
              </tr>
              <tr>
                <td className="px-2 py-1">Custom Points</td>
                <td className="px-2 py-1">
                  {pointsBreakdown["admin-assigned"] || 0}
                </td>
              </tr>
              <tr>
                <td className="px-2 py-1">Base Points</td>
                <td className="px-2 py-1">
                  {community?.configurables?.COMMUNITY_BASE_POINT_KEY || 0}
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={2} className="text-center">
                <div className="theme-text-subtitle-2">
                  No statistics available yet
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

const KarmaPointsHeaderButton = KarmaPointsHeaderButtonComponent;

export default KarmaPointsHeaderButton;
