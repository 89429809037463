import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { Group } from "../../types/group/group.type";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import { CommunityService, GroupService } from "../../_service";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import LeaderBoardComponent from "../leaderboard-component";
import RightSideModal from "../right-side-modal.component";

interface LeaderBoardWidgetProps {
  group?: Group;
  memberPreviewLimit: number;
  isCommunityLeaderBoard: Boolean;
}

/**
 * Widget to display  top contributors of  community | group.
 * @param {int} memberPreviewLimit - No. of members to be displayed.
 * @param {Boolean} isCommunityLeaderBoard - Whether to display leader board for community or group. Default is community.
 */
export default function LeaderBoardWidget({
  group,
  memberPreviewLimit = 4,
  isCommunityLeaderBoard = true,
}: LeaderBoardWidgetProps) {
  const { analyticsService } = useAppService();

  // load the subscriber list once, or per community or group change

  const [isLoading, setIsLoading] = useState(false);
  const [contributors, setContributors] = useState<any>();
  const [groupContributors, setGroupContributors] = useState<any>();
  const [isContributorsListVisible, setIsContributorsListVisible] =
    useState(false);

  const { addToast } = useToast();

  const { community, user } = useAppSelector((state) => {
    return {
      community: state.community,
      user: state.auth,
    };
  });

  const communityId = community?.id;
  const groupId = group?.id;

  const getCommunityLeaderboardData = async (communityId : string) => {
    const leaderboardData = await CommunityService.getLeaderboardV2Paginated(communityId, 1, memberPreviewLimit);
    setContributors(leaderboardData.leaderboard);
  }

  const getGroupLeaderboardData = async (groupId : string) => {
    const leaderboardData = await GroupService.getLeaderboardV2Paginated(groupId, 1, memberPreviewLimit);
    setGroupContributors(leaderboardData.leaderboard);
  }
  // Get leaderboard data for community
  useEffect(() => {
    if (communityId) {
      try {
        setIsLoading(true);
        getCommunityLeaderboardData(communityId);
        setIsLoading(false);
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
      }
    }
    if(groupId){
      try {
        setIsLoading(true);
        getGroupLeaderboardData(groupId);
        setIsLoading(false);
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
      }
    }
  }, [communityId, groupId, isCommunityLeaderBoard]);

  // Hide widget if there is no contributors available
  if (!isLoading && (contributors === undefined || contributors.length === 0)) {
    return <></>;
  }
  // Function to return points in the format of 1K, 1M, 1B etc.
  // @param {Number} points - Points to be formatted.
  const formatPoints = (points: number) => {
    if (points >= 1000000000) {
      return `${(points / 1000000000).toFixed(1)}B`;
    } else if (points >= 1000000) {
      return `${(points / 1000000).toFixed(1)}M`;
    } else if (points >= 1000) {
      return `${(points / 1000).toFixed(1)}K`;
    } else {
      return points;
    }
  };

  return (
    <>
      <div className="theme-bg-surface mt-3 rounded">
        <button
          className="flex px-4 py-2 border-b theme-border-default select-none cursor-pointer w-full"
          onClick={() => {
            setIsContributorsListVisible(true);
            analyticsService.track("widget-leaderboard", {
              clickOn: "header",
              widgetType: "leaderboard",
            });
          }}>
          <h4 className="font-bold ">
            {isCommunityLeaderBoard ? (
              <I18>Community Top Contributors</I18>
            ) : (
              <I18>Group Top Contributors</I18>
            )}
          </h4>
        </button>
        {/* members section */}
        <div className="p-2 pb-2">
          {/* group leaderboard */}
          {!isCommunityLeaderBoard && groupContributors && (
             <div className="">
             {groupContributors
               .slice(0, memberPreviewLimit)
               .map((contributor: any, index: number) => (
                 <div
                   onClick={() => {
                     const eventData = {
                       clickOn: "user",
                       widgetType: "leaderboard",
                     };

                     analyticsService.track("widget-leaderboard", eventData);
                     // open the user page
                     window.open(
                       createUserWallPageRoute(contributor.user.id, "activity")
                     );
                   }}
                   className=" mb-5 flex justify-between items-center cursor-pointer"
                   key={index}>
                   <div
                     className="flex items-center space-x-1"
                     style={{ maxWidth: "calc(100% - 75px)" }}>
                     <span className="font-semibold text-xs w-6">
                       #{index < 10 ? 0 : ""}
                       {index + 1}
                     </span>
                     <Avatar
                       user={contributor.user}
                       noName
                       size={30}
                       className="w-8"
                       extraInfo={undefined}
                     />
                     <span className="font-semibold  word-breaker">
                       {contributor.user.name}
                     </span>
                   </div>
                   <span>
                   {formatPoints(contributor.karmaPoints?.grandTotal || 0) + (community?.configurables?.COMMUNITY_BASE_POINT_KEY || 0)} <I18>points</I18>
                   </span>
                 </div>
               ))}
           </div>
          )}

          {/* community leaderboard */}
          {isCommunityLeaderBoard && contributors && (
            <div className="">
              {contributors
                .slice(0, memberPreviewLimit)
                .map((contributor: any, index: number) => (
                  <div
                    onClick={() => {
                      const eventData = {
                        clickOn: "user",
                        widgetType: "leaderboard",
                      };

                      analyticsService.track("widget-leaderboard", eventData);
                      // open the user page
                      window.open(
                        createUserWallPageRoute(contributor.user.id, "activity")
                      );
                    }}
                    className=" mb-5 flex justify-between items-center cursor-pointer"
                    key={index}>
                    <div
                      className="flex items-center space-x-1"
                      style={{ maxWidth: "calc(100% - 75px)" }}>
                      <span className="font-semibold text-xs w-6">
                        #{index < 10 ? 0 : ""}
                        {index + 1}
                      </span>
                      <Avatar
                        user={contributor.user}
                        noName
                        size={30}
                        className="w-8"
                        extraInfo={undefined}
                      />
                      <span className="font-semibold  word-breaker">
                        {contributor.user.name}
                      </span>
                    </div>
                    <span>
                      {formatPoints((contributor.karmaPoints?.grandTotal || 0) + (community?.configurables?.COMMUNITY_BASE_POINT_KEY || 0))} <I18>points</I18>
                    </span>
                  </div>
                ))}
            </div>
          )}

          {/* show all link */}

          <div className="my-2">
            <TextButton
              label="Show All"
              onClick={(e: any) => {
                setIsContributorsListVisible(true);
                const eventData = {
                  clickOn: "show-all",
                  widgetType: "leaderboard",
                };

                analyticsService.track("widget-leaderboard", eventData);
              }}
            />
          </div>
          <RightSideModal
            active={isContributorsListVisible}
            setActive={setIsContributorsListVisible}
            width={500}>
            <LeaderBoardComponent
              addToast={addToast}
              user={user}
              community={community}
              contributors={isCommunityLeaderBoard ? contributors : groupContributors}
              group={group}
              isCommunityLeaderBoard={isCommunityLeaderBoard}
              onUpdateContributors={function (e: any): void {
                setContributors(e);
              }}
            />
          </RightSideModal>
        </div>
      </div>
    </>
  );
}
