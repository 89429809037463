import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastNotifier } from "./contexts/toastr.context";
import { useAppSelector } from "./hooks/redux.hook";
import { useAppService } from "./hooks/use-app-service";
import useHelpScout from "./hooks/use-help-scout.hook";
import { Loader } from "./_components";
import { CommunityVerificationPrompt } from "./_components/community/community-verification-prompt.component";
import MainLoader from "./_components/main-loader.component";
import AuthAppShimmer from "./_components/shimmers/auth-app-shimmer.component";
import { history } from "./_config";
import { socketEndpointBase } from "./_config/endpoint.config/endpoint.config";
import { MAIN_DOMAIN_SUFFIX } from "./_config/helper.config";
import { mixPanel } from "./_config/mixpanel.config";
import { CommunityService, NFTService, UserService } from "./_service";
import { createSession } from "./_store/_actions/auth.actions";
import { setCommunity } from "./_store/_actions/community.actions";
import { setCommunityPac } from "./_store/_actions/community_pac.actions";
import { updateGroupsByIds } from "./_store/_actions/group.actions";

const EmbedApp = React.lazy(() => import("./_components/embed/embed-app"));
const DualVerificationPopup = React.lazy(() =>
  import("./_components/community/dual-verificiation-popup")
);
const AuthApp = React.lazy(() => import("./_components/auth-app.component"));
const UnAuthApp = React.lazy(() =>
  import("./_components/unauth-app.component")
);

function AppComponent({
  user,
  community,
  // dispatch
  setUser,
  setCommunity,
  setCommunityPac,
  updateGroupsByIds,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { analyticsService } = useAppService();

  const groups = useAppSelector((state) => state.groups);

  // admin verification related
  const [isUserAdminVerified, setIsUserAdminVerified] = useState(false);
  const [checkingForAdminVerification, setCheckingForAdminVerification] =
    useState(false);
  const [checkForVerificationComplete, setCheckForVerificationComplete] =
    useState(false);

  // check what appUrl starts with
  const subDomain = window.location.hostname.split(".")[0];
  const port = window.location.port;

  // check if we have a redirect url
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURL = urlParams.get("redirect");
  const token = urlParams.get("token");
  const auth_token = urlParams.get("auth_token");
  const access_token = urlParams.get("access_token");
  // variable to perform some action
  const action = urlParams.get("action");

  // one signal related
  const [isLoadingUser, setIsLoadingUser] = useState(false); // check if synced with server, will turn true, when we have a user and have updated his/her userId on server

  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useHelpScout(community);

  // useEffect(() => {
  //   // if not already initialized , initialize it and only on subdomain app, and there is not action to be done (we are just initializing app)
  //   if (!oneSignalInitialized && subDomain === "app" && !action) {
  //     try {

  //       // one signal related
  //       window.OneSignal = window.OneSignal || [];
  //       const OneSignal = window.OneSignal;
  //       // initate the onesignal

  //       OneSignal.push(() => {
  //         // initialize function
  //         OneSignal.init(
  //           {
  //             appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
  //             notifyButton: {
  //               enable: true,
  //             },
  //             subdomainName: "pensil-app",
  //             allowLocalhostAsSecureOrigin: true,
  //             promptOptions: {
  //               slidedown: {
  //                 enabled: true,
  //                 actionMessage: "Notifications are a essential part of Pensil.",
  //                 acceptButtonText: "I understand",
  //                 cancelButtonText: "Cancel",
  //                 // categories: {
  //                 //   tags: [
  //                 //     {
  //                 //       tag: "react",
  //                 //       label: "ReactJS",
  //                 //     },
  //                 //     {
  //                 //       tag: "angular",
  //                 //       label: "Angular",
  //                 //     },
  //                 //     {
  //                 //       tag: "vue",
  //                 //       label: "VueJS",
  //                 //     },
  //                 //     {
  //                 //       tag: "js",
  //                 //       label: "JavaScript",
  //                 //     }
  //                 //   ]
  //                 // }
  //               }
  //             },
  //             welcomeNotification: {
  //               "title": "Welcome to Pensil",
  //               "message": "Thanks for subscribing!",
  //             }
  //           },
  //           // //Automatically subscribe to the new_app_version tag
  //           // OneSignal.sendTag("new_app_version", "new_app_version", tagsSent => {
  //           //   // Callback called when tag has finished sending
  //           //   console.log('new_app_version TAG SENT', tagsSent);
  //           // })
  //         );
  //       });

  //       // listen to on subscription change, so that we can get userId when updated
  //       OneSignal.on('subscriptionChange', function (isSubscribed) {
  //         console.log("The user's subscription state is now: ", isSubscribed);
  //         OneSignal.getUserId().then(id => {
  //           if (id) setOneSignalUserId(id);
  //           console.log({ oneSignalUserId: id });
  //           setOneSignalUserIdRetreived(true);
  //         });
  //       });

  //       // // try to get userId anyway
  //       if (OneSignal.getUserId) {
  //         OneSignal.getUserId().then(id => {
  //           if (id) setOneSignalUserId(id);
  //           console.log({ oneSignalUserId: id });
  //         });
  //       } else {
  //         console.error("OneSignal.getUserId could not be started");
  //       }

  //       // set oneSignal as initialized
  //       setOneSignalInitialized(true);
  //     } catch (error) {
  //       setOneSignalUserIdSynced(true);
  //       console.log({ error, message: "One signal could not be initialized" });
  //     }
  //   }
  // }, [subDomain, action, oneSignalInitialized]);

  // once we have user && oneSignalUserId && !oneSignalUserIdSynced, update the token on server
  // useEffect(() => {
  //   if (user && oneSignalUserId && !oneSignalUserIdSynced) {
  //     // sync the userId
  //     // TODO: add check if this is already synced [using local storage probably]
  //     UserService.syncOneSignalWebUserId(user, oneSignalUserId)
  //       .then(response => {
  //         // all done
  //         setOneSignalUserIdSynced(true);
  //       })
  //       .catch(error => {
  //         console.log({ error, message: "Could not sync oneSignalUserId to server" });
  //         setOneSignalUserIdSynced(true);
  //       });
  //   }
  // }, [user, oneSignalUserId, oneSignalUserIdSynced]);

  // get community domain to load
  const communityDomain = getCommunityDomainToLoad(subDomain);
  // get community if needed, if communityDomain is set
  useEffect(() => {
    if (communityDomain) {
      // get community
      setIsLoading(true);
      CommunityService.getCommunity(communityDomain)
        .then((response) => {
          setCommunity(response.community);
          if (response.community.pac) {
            setCommunityPac(response.community.pac);
          }
          analyticsService.track("load-community", {
            userType: user?.role,
            communityPlan: response.community.plan ? response.community.plan.planType : "free"
          });
        })
        .catch((err) => {
          console.log({ err });
          if (err.response && err.response.data) {
            const { data } = err.response;
            if (data.message) {
            }
          }
        })
        .finally(() => {
          setIsLoaded(true);
          setIsLoading(false);
        });
    }
  }, [communityDomain, setCommunity]);

  // admin verification related
  useEffect(() => {
    if (community && community.needsAdminVerification) {
      // if user is not admin, verified, check for verification once
      if (
        user &&
        !isUserAdminVerified &&
        !checkForVerificationComplete &&
        !checkingForAdminVerification
      ) {
        setCheckingForAdminVerification(true);
        UserService.getUserProfile({ token: user.token })
          .then(({ user: userNew }) => {
            // save the user
            localStorage.setItem(
              "pensil.user",
              JSON.stringify({
                ...userNew,
                token: user.token,
                email: user.email ? user.email : userNew.email,
                mobile: user.mobile ? user.mobile : userNew.mobile,
              })
            );
            if (
              Array.isArray(userNew.adminVerifiedCommunities) &&
              userNew.adminVerifiedCommunities.includes(community.id)
            ) {
              setIsUserAdminVerified(true);
            }
            setCheckForVerificationComplete(true);
            setCheckingForAdminVerification(false);
            setUser({
              ...userNew,
              token: user.token,
              email: user.email ? user.email : userNew.email,
              mobile: user.mobile ? user.mobile : userNew.mobile,
            });
          })
          .catch((error) => {
            // something went wrong
            setCheckForVerificationComplete(true);
            setCheckingForAdminVerification(false);
            console.log({ error });
          });
      }
    }
  }, [
    community,
    user,
    isUserAdminVerified,
    checkForVerificationComplete,
    checkingForAdminVerification,
  ]); // dont add set user here, no need

  // listen to routes
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // check route is /leaderboard and apply two-column class
      setCurrentPath(window.location.pathname);
    });

    return () => {
      unlisten();
    };
  }, []);

  // load user profile from token if needed
  const communityAuthUrl = community?.authRedirectUrl;
  const communityId = community?.id;
  useEffect(() => {
    if (communityAuthUrl && (auth_token || access_token)) {
      setIsLoadingUser(true);
      CommunityService.verifyThirdPartyToken(
        communityId,
        auth_token ?? access_token
      )
        .then(({ user }) => {
          // save the user
          localStorage.setItem("pensil.user", JSON.stringify(user));
          setUser(user);
          // go to origin
          window.location.href = window.location.origin + "?action=" + action;
        })
        .catch((err) => {
          // something went wrong
          setIsLoadingUser(false);
          console.log({ message: "Failed loading user from token", err });
        });
    }
  }, [communityAuthUrl, communityId, auth_token, access_token, action]);

  //
  useEffect(() => {
    if (token) {
      loadUserProfileFromToken();
    }
  }, [token]);

  // check for nft gated access if required
  const userId = user?.id;
  const walletAddress = user?.walletAddress;
  // function to check for the gated group access
  async function checkForGatedGroupAccess(communityId) {
    try {
      const { accessibleGroupIds } = await NFTService.checkForGatedGroupAccess(
        communityId
      );
      await updateGroupsByIds(
        accessibleGroupIds,
        {
          myRole: "user",
          joinStatus: "joined",
        },
        (prevValue) => prevValue?.myRole !== "admin"
      );
    } catch (error) {
      console.log("ERROR checkForGatedGroupAccess failed", error);
    }
  }
  useEffect(() => {
    if (userId && communityId && walletAddress) {
      checkForGatedGroupAccess(communityId);
    }
  }, [userId, communityId, walletAddress]);

  /**
   * Load user profile from our token
   */
  const loadUserProfileFromToken = () => {
    UserService.getUserProfile({ token })
      .then(({ user }) => {
        // save the user
        localStorage.setItem("pensil.user", JSON.stringify({ ...user, token }));
        // go to origin
        window.location.href = window.location.origin + "?action=" + action;
      })
      .catch((error) => {
        // something went wrong
        console.log({ error });
      });
  };

  // if we have a redirect url and user, redirect the user to that with current token
  if (redirectURL && user) {
    if (["google-login", "facebook-login"].includes(action)) {
      // clear session
      localStorage.clear("pensil.user");
      mixPanel.reset();
      // redirect
      window.location.reload();
      return <div>Redirecting...</div>;
    } else {
      window.location.href =
        redirectURL + "?token=" + user.token + "&action=autosub";
      return <div>Redirecting...</div>;
    }
  }

  // if we have an embed url, show embed portion
  if (subDomain === "embed") {
    return (
      <Suspense fallback={<MainLoader />}>
        <EmbedApp />
      </Suspense>
    );
  }

  // if subdomain is our main app, render that
  if (
    window.location.hostname === "localhost" ||
    (subDomain === "app" &&
      window.location.hostname ===
        "app" + process.env.REACT_APP_MAIN_DOMAIN_SUFFIX)
  ) {
    // if we have a redirectURL and logout action, do it
    if (redirectURL && action === "logout") {
      // clear session
      localStorage.clear("pensil.user");
      mixPanel.reset();
      // redirect
      window.location.href = redirectURL;
      return <div>Redirecting...</div>;
    }

    // OneSignalRelated - if we have user and userId and userId is yet updating on server
    // console.log({
    //   user,
    //   oneSignalUserId,
    //   oneSignalUserIdSynced: !oneSignalUserIdSynced
    // })
    // if (user && ((oneSignalUserIdRetreived && oneSignalUserId) || !oneSignalUserIdRetreived) && !oneSignalUserIdSynced) {
    //   return <div>Please wait, updating notification configuration...</div>;
    // }

    return (
      <div className="App">
        <CustomThemeData community={community} />
        {user ? (
          <Suspense fallback={<AuthAppShimmer />}>
            <AuthApp user={user} community={community} />
          </Suspense>
        ) : (
          <Suspense fallback={<MainLoader />}>
            <UnAuthApp />
          </Suspense>
        )}
        <ToastNotifier />
      </div>
    );
  } else {
    // on some community
    // check for logout action
    // if we have a redirectURL and logout action, do it
    if (action === "logout") {
      // clear session
      localStorage.clear("pensil.user");
      mixPanel.reset();
      // redirect
      window.location.href = window.location.origin;
      return <div>Logging out...</div>;
    }
  }

  // show loader if loading, (loading community)
  if (isLoading || !isLoaded || isLoadingUser) {
    return (
      <div className="h-screen bg-default flex justify-center items-center flex-col">
        <CustomThemeData community={community} />
        <MainLoader />
        <span className="text-gray-500">
          Please wait while we are loading data...
        </span>
      </div>
    );
  }

  // if loaded, check if community is there, other wise show 404 page

  if (!community) {
    return (
      <div className="h-screen bg-default flex justify-center items-center flex-col">
        <CustomThemeData community={community} />
        <span className="text-gray-500 mb-2">
          It seems like this community is not available.
        </span>
        <a
          className="theme-text-primary"
          target="_blank"
          rel="noopener noreferrer"
          href={
            process.env.REACT_APP_CLIENT_URL +
            (port ? ":" + port : "") +
            "/create-community"
          }>
          Create your own
        </a>
      </div>
    );
  }

  // check for normal token now
  if (token) {
    return (
      <div className="h-screen bg-default flex justify-center items-center flex-col">
        <CustomThemeData community={community} />
        <div className="my-2">
          <MainLoader />
        </div>
        <span className="text-gray-500">
          Please wait while we are loading data...
        </span>
      </div>
    );
  }

  // if not logged in redirect to main app
  if (!user) {
    // check for auth token first

    if (community && community.authRedirectUrl) {
      if (auth_token || access_token) {
        // we have a auth token so we can use that to get the user profile, this is happening in an use effect
        return (
          <div className="h-screen bg-default flex justify-center items-center flex-col">
            <CustomThemeData community={community} />
            <div className="my-2">
              <MainLoader />
            </div>
            <span className="text-gray-500">
              Please wait while we are loading data...
            </span>
          </div>
        );
      }
    }

    // check for normal token now
    if (token) {
      return (
        <div className="h-screen bg-default flex justify-center items-center flex-col">
          <CustomThemeData community={community} />
          <div className="my-2">
            <MainLoader />
          </div>
          <span className="text-gray-500">
            Please wait while we are loading data...
          </span>
        </div>
      );
    }
  } else {
    if (token) {
      window.location.href = window.location.origin + "?action=" + action;
    }
  }

  // // check if user is community admin and community is expired
  // if (user && community && community.isPlanExpired && community.createdBy.id === user.id) {
  //   return (
  //     <UserPlanSelectorPage user={user} community={community} />
  //   );
  // }

  // check if user is admin verified for community
  if (
    community.needsAdminVerification &&
    (checkingForAdminVerification || !checkForVerificationComplete)
  ) {
    // if we have logged in user, show verification page, else show login page
    if (user) {
      return (
        <div className="h-screen bg-default flex justify-center items-center flex-col">
          <CustomThemeData community={community} />
          <div className="my-2">
            <MainLoader />
          </div>
          <span className="text-gray-500">
            Please wait while we are checking for admin verification...
          </span>
        </div>
      );
    } else {
      return (
        <Suspense fallback={<MainLoader />}>
          <CustomThemeData community={community} />
          <UnAuthApp />
        </Suspense>
      );
    }
  }
  if (
    community.needsAdminVerification &&
    !community.joinVerifiedByAdmin &&
    community.myRole !== "admin"
  ) {
    // if we have logged in user, show verification page, else show login page
    if (user) {
      return (
        <>
          <CustomThemeData community={community} />
          <CommunityVerificationPrompt />
        </>
      );
    } else {
      return (
        <Suspense fallback={<MainLoader />}>
          <CustomThemeData community={community} />
          <UnAuthApp />
        </Suspense>
      );
    }
  }

  // if we have mandatory login
  if (community.isLoginRequired && !user) {
    return (
      <Suspense fallback={<MainLoader />}>
        <CustomThemeData community={community} />
        <UnAuthApp />
      </Suspense>
    );
  }

  // dual verification popup
  if (
    user &&
    community.isMobileAndEmailVerificationRequired &&
    (!user.email || !user.mobile) &&
    community.myRole !== "admin"
  ) {
    return (
      <Suspense fallback={<Loader />}>
        <CustomThemeData community={community} />
        <DualVerificationPopup user={user} community={community} />
      </Suspense>
    );
  }

  // else we have a community
  return (
    <div className="App bg-default">
      <CustomThemeData community={community} />
      <Suspense fallback={<MainLoader />}>
        {
          // check if url is for login or signup
          (currentPath.startsWith("/login") ||
            currentPath.startsWith("/register")) &&
          !user ? (
            <UnAuthApp />
          ) : (
            <AuthApp user={user} community={community} />
          )
        }
      </Suspense>
      <ToastNotifier />
    </div>
  );
}

export function CustomThemeData({ community }) {
  return community ? (
    <link
      rel="stylesheet"
      href={socketEndpointBase + "/theme/" + community.id + "/style.css"}
      onLoad={(e) => {
        var style = getComputedStyle(document.body);
        // Get CSS variable's value
        const bgColor = style.getPropertyValue("--theme-background-color");
        localStorage.setItem(
          "pensil.theme",
          bgColor === "#141519" ? "dark" : "light"
        );
      }}
    />
  ) : (
    <></>
  );
}

/**
 * get community to load using
 * community subdomain or hardcoded check for alternate domains
 * @returns
 */
function getCommunityDomainToLoad(subDomain = "") {
  // check if we have community subdomain in env file
  if (process.env.REACT_APP_COMMUNITY_SUBDOMAIN) {
    return process.env.REACT_APP_COMMUNITY_SUBDOMAIN;
  }

  // check if subdomain matches, return it if so
  if (
    window.location.hostname.endsWith(MAIN_DOMAIN_SUFFIX) &&
    subDomain !== "app" &&
    subDomain !== "embed"
  )
    return subDomain;

  // return the host itself, server will handle
  return window.location.hostname;
}

const dtp = (dispatch) =>
  bindActionCreators(
    {
      setCommunity: setCommunity,
      setCommunityPac: setCommunityPac,
      setUser: createSession,
      updateGroupsByIds: updateGroupsByIds,
    },
    dispatch
  );

const App = connect(
  (state) => ({
    user: state.auth,
    community: state.community,
  }),
  dtp
)(AppComponent);

export default App;
