import moment from "moment";
import { history } from "../../../_config";
import { createUserWallPageRoute } from "../../../_pages/user-wall.page";
import Validator from "../../../_utils/validator";
import I18 from "../../atoms/i18";
import { Avatar, AvatarCommunityPost } from "../../avatar.component";
import PostMenuActions from "./post-menu-actions.component";

/**
 * post options and user info
 * @param {*} param0
 * @returns
 */
export default function PostHeader({
  post,
  updatePost,
  deletePost,
  user,
  group,
  setEmbedModalVisible,
  setIsProcessing,
  addToast,
  isCommunityPost,
}) {
  const sample = moment
    .duration(moment(new Date()).diff(moment(post.createdAt)))
    .asDays();
  const isSharedPost = post.sharedId !== undefined && post.sharedId != null;
  const { createdAt, createdBy, groupRole } = isSharedPost ? post.shared : post;

  const { hideCreator, hideDate } = post.preferences ?? {};

  return (
    <div className="PostHeader flex justify-between items-center">
      {isCommunityPost && !hideCreator ? (
        <AvatarCommunityPost
          user={createdBy}
          post={post}
          extraInfo={
            !hideDate
              ? sample < 1
                ? moment(createdAt).fromNow()
                : moment(createdAt).format("DD MMM YYYY")
              : ""
          }
          size={42}
          className="cursor-pointer"
          tag={
            groupRole === "admin" ? (
              <div className="theme-theme-bg-primary-light theme-text-primary mx-2 font-semibold text-xxs px-1 rounded">
                <I18>Admin</I18>
              </div>
            ) : groupRole === "moderator" ? (
              <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                <I18>Moderator</I18>
              </div>
            ) : groupRole === "user" ? (
              <></>
            ) : (
              <></>
            )
          }
          onClick={(e) => {
            // open the user page
            history.push(createUserWallPageRoute(createdBy.id, "activity"));
          }}
        />
      ) : (
        /// Hide post author profile picture
        !hideCreator && (
          <>
            <Avatar
              user={createdBy}
              extraInfo={
                <span>
                  {Validator.hasValue(createdBy?.shortBio)
                    ? createdBy?.shortBio
                    : !hideDate
                    ? sample < 1
                      ? moment(createdAt).fromNow()
                      : moment(createdAt).format("DD MMM YYYY")
                    : "  "}
                </span>
              }
              size={42}
              className="cursor-pointer"
              tag={
                <span className="flex items-center gap-2">
                  {groupRole === "admin" ? (
                    <div className="theme-theme-bg-primary-light theme-text-primary mx-2 font-semibold text-xxs px-1 rounded">
                      <I18>Admin</I18>
                    </div>
                  ) : groupRole === "moderator" ? (
                    <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                      <I18>Moderator</I18>
                    </div>
                  ) : groupRole === "user" ? (
                    <></>
                  ) : (
                    <div></div>
                  )}
                  {Validator.hasValue(createdBy?.shortBio) && (
                    <span className="text-xxs font-semibold theme-text-heading-2">
                      {!hideDate
                        ? sample < 1
                          ? moment(createdAt).fromNow()
                          : moment(createdAt).format("DD MMM YYYY")
                        : "  "}
                    </span>
                  )}
                </span>
              }
              onClick={(e) => {
                // open the user page
                history.push(createUserWallPageRoute(createdBy.id, "activity"));
              }}
            />
          </>
        )
      )}
      {/* Hide Post actions of post Author profile picture is hidden */}
      {!hideCreator && (
        <PostMenuActions
          addToast={addToast}
          deletePost={deletePost}
          group={group}
          post={post}
          setEmbedModalVisible={setEmbedModalVisible}
          setIsProcessing={setIsProcessing}
          updatePost={updatePost}
          user={user}
        />
      )}
    </div>
  );
}
